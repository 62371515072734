.login {
    background-color: white;
    display: flex;
    margin-top: 20px;
  /* margin-top: 10vh; */
    justify-content: center; 
    align-items: center;
}

.login__logo {
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
    width: 80px;
    margin-right: auto;
    margin-left: auto;
  }

.login__container {
  box-shadow: 0 0 6px rgba(55,67,177,.15);
  max-width: 560px;
  /* min-width: 400px; */
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  /* border: 1px solid lightgray; */
  padding: 40px;

}

.login__container > div > h1 {
    font-weight: 600;
    font-size: 32px;
    padding: 0px 0;
  }

.login__container > form {
  width: 100%;
}

.login__container > form > h5 {
    margin-bottom: 5px;
}

.login__container > form > input {
    margin-bottom: 25px;
    margin-top: 10px;
  }

.login__container > p {
    margin-top: 15px;
    font-size: 10px;

}

.login__signInButton {
    font-weight: 600;
    font-size: 18px;
    background: #3743B1;
    color: whitesmoke;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    margin: 10px 0;
    border: none;
    outline: none;
  }

  .login__signInButton:hover {
    background-color: white;
    color: #3743B1;
    border: 1px solid;
    border-color: #3743B1;
    outline: none;
  }

  .login__registerButton {
    font-weight: 600;
    font-size: 18px;
    border-radius: 2px;
    width: 100%;
    height: 50px;
    border: 1px solid;
    margin: 20px 0;
    border-color: darkgray;
  }


  
  