.verification__view{
    display: grid;
    place-items: center;
    height: 100vh;
    padding: 40px;
    background-color: whitesmoke;
}

.verification__container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 2px 0 8px rgba(0,0,0,.15) ;
}

.verification__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding:22px 25px;
}

.verification__body > p{
    font-weight: 500;
    font-size: 20px;
    color: grey;
    padding: 10px;
}

.verification__body > h5{
   padding: 40px 0;
    font-size: 16px;
    color: rgb(175, 175, 175);
}

.verification__body > button{
    width: 120px;
    height: 40px;
    color: white;
    background-color: #30b3f0;
    font-weight: 520;
    font-size: 14px;
    outline: none;
    border: none;
    letter-spacing: 0.05em;
 }

.verification__header{
    display: flex;
    justify-content: center;
    padding: 30px;
}

.verification__header > img{
    width: 150px;

}

.otp__box {
    border: 1px solid rgb(181, 181, 181);
    width: 35px !important;
    height: 30px !important;
    font-weight: 500;
}