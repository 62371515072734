.chat {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.wrapper{
  display: flex;
  flex-direction: row;

}

.chat__header {
    padding : 8px 0px 8px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
    /* background-color: whitesmoke; */
    height: 10vh;
} 

.chat__header__info {
    flex: 1;
    padding-left: 20px;
}

button-send {
    border: none !important;
    outline:none !important;
}

.chat__header__right {
    display: flex;
    justify-content:center;
    min-width: 100px;
    font-weight: bold;
}

.chat__body {
  
   padding: 30px;
   height: 82vh;
   overflow: scroll;
   overflow-y:scroll;
   overscroll-behavior-y: contain;
   scroll-snap-type: y proximity;
   /* display: flex; 
    align-items: flex-end; */
   
}

.chat__msg__container {
    width: 100%;
}

.chat__date {
    display: flex;
    justify-content: center;
    color : rgb(101, 100, 100) ;
}

.chat__message {
    /* position: relative; */
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 10px;
    width: fit-content;
    background-color: #EEEFFF; 
    /* margin-bottom: 30px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}

.chat__reciever {
    margin-left: auto;
    background-color: #f0f0f0;
}

.msg__img {
  margin: 10px;
  width: 150px;
  height: 150px;
  object-fit: fill;
}

.chat__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10px;
    /* border-top: 1px solid lightgray; */
    padding: 20px;
    margin-bottom: 20px;
    z-index: 1000;
    /* background-color: white; */
}

::placeholder {
  color: #3743B1;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #3743B1;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: #3743B1;
}

.chat__footer > form {
    flex : 1;
    display: flex;
    border-radius: 50px;
    padding-left: 20px;
    color: #3743B1;
    border: 1px solid #3743B1;
  
}

.chat__footer > form > input {
    flex: 1;
    border-radius: 30px;
    padding: 10px;
    border: none;
    font-size: larger;
}

.chat__footer > form > button {
    display: none;
}

.chat__footer > .MuiSvgIcon-root {
    margin: 0 10px ;
    color : rgb(101, 100, 100) ; 
}

.svg_icons{
    transform: scale(1.3);
  }

.a {
    color: rgb(170, 214, 215);
}

.pop__over {
padding: 15px;
display: flex;

}

.pop__over__left {
    display: flex;
    flex-direction: column;
}

.pop__over__buttons {
    display: flex;
    flex-wrap: nowrap;
}

.pop__over__buttons > .MuiSvgIcon-root {
    color : rgb(101, 100, 100) ; 
}

.pop__over__buttons > span {
    color : rgb(101, 100, 100) ; 
    font-size: larger;
    margin: auto 0px;
    padding-bottom: 3px;
}

.pop__over__rate {
    border: 1px solid #5bc0de;
    color: #5bc0de;
    padding:  5px;
    height: 35px;
    border-radius: 8px;
    margin: 10px 0 0 10px;
}


.message__container {
display: flex;

}
.message__container > span {
  padding: 0 10px;
}

.reciever__container {
  flex-direction: row-reverse;
}

.reciever__container > span {
  padding-left: 15px;
}

  /* Right triangle placed top left flush. */
  .tri-right.border.left-top:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: -40px;
      right: auto;
    top: -8px;
      bottom: auto;
      border: 32px solid;
      border-color: #666 transparent transparent transparent;
      
  }
  .tri-right.left-top:after{
      
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: -15px;
      right: auto;
    top: 0px;
      bottom: auto;
      border: 22px solid;
      border-color: white transparent transparent transparent;
  }
  
  /* Right triangle, left side slightly down */
  .tri-right.border.left-in:before {
      
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: -40px;
      right: auto;
    top: 30px;
      bottom: auto;
      border: 20px solid;
      border-color: #666 #666 transparent transparent;
  }
  .tri-right.left-in:after{
      content: ' ';
      
      position: absolute;
      width: 0;
      height: 0;
    left: -20px;
      right: auto;
    top: 38px;
      bottom: auto;
      border: 12px solid;
      border-color: lightyellow lightyellow transparent transparent;
  }
  
  /*Right triangle, placed bottom left side slightly in*/
  .tri-right.border.btm-left:before {
      
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: -8px;
    right: auto;
    top: auto;
      bottom: -40px;
      border: 32px solid;
      border-color: transparent transparent transparent #666;
  }
  .tri-right.btm-left:after{
      
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 0px;
    right: auto;
    top: auto;
      bottom: -20px;
      border: 22px solid;
      border-color: transparent transparent transparent lightyellow;
  }
  
  /*Right triangle, placed bottom left side slightly in*/
  .tri-right.border.btm-left-in:before {
      
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 30px;
    right: auto;
    top: auto;
      bottom: -40px;
      border: 20px solid;
      border-color: #666 transparent transparent #666;
  }
  .tri-right.btm-left-in:after{
      
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 38px;
    right: auto;
    top: auto;
      bottom: -20px;
      border: 12px solid;
      border-color: lightyellow transparent transparent lightyellow;
  }
  
  /*Right triangle, placed bottom right side slightly in*/
  .tri-right.border.btm-right-in:before {
      
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: auto;
      right: 30px;
      bottom: -40px;
      border: 20px solid;
      border-color: #666 #666 transparent transparent;
  }
  .tri-right.btm-right-in:after{
      
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: auto;
      right: 38px;
      bottom: -20px;
      border: 12px solid;
      border-color: lightyellow lightyellow transparent transparent;
  }
  /*
      left: -8px;
    right: auto;
    top: auto;
      bottom: -40px;
      border: 32px solid;
      border-color: transparent transparent transparent #666;
      left: 0px;
    right: auto;
    top: auto;
      bottom: -20px;
      border: 22px solid;
      border-color: transparent transparent transparent lightyellow;
  
  /*Right triangle, placed bottom right side slightly in*/
  .tri-right.border.btm-right:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: auto;
      right: -8px;
      bottom: -40px;
      border: 20px solid;
      border-color: #666 #666 transparent transparent;
  }
  .tri-right.btm-right:after{
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: auto;
      right: 0px;
      bottom: -20px;
      border: 12px solid;
      border-color: lightyellow lightyellow transparent transparent;
  }
  
  /* Right triangle, right side slightly down*/
  .tri-right.border.right-in:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: auto;
      right: -40px;
    top: 30px;
      bottom: auto;
      border: 20px solid;
      border-color: #666 transparent transparent #666;
  }
  .tri-right.right-in:after{
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: auto;
      right: -20px;
    top: 38px;
      bottom: auto;
      border: 12px solid;
      border-color: lightyellow transparent transparent lightyellow;
  }
  
  /* Right triangle placed top right flush. */
  .tri-right.border.right-top:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: auto;
      right: -40px;
    top: -8px;
      bottom: auto;
      border: 32px solid;
      border-color: #666 transparent transparent transparent;
  }
  .tri-right.right-top:after{
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    left: auto;
      right: -15px;
    top: 0px;
      bottom: auto;
      border: 20px solid;
      border-color: #c9fca2 transparent transparent transparent;
  }
  
