.heading{
    text-align: center;
    font-size: 2rem;
    margin: 2vw auto;
    margin-bottom: 2.5rem;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
}
.container{
    margin-top: 20px;
        width: 50%;
        box-sizing: border-box;

}
#formHorizontalPassword{
    border:none;
    border-bottom: 1px solid rgb(67, 68, 68);
}
.form-control:focus {
    box-shadow: none;
}

.key{
    color: rgb(67, 68, 68);
        font-weight: 500;
}

/* .key{
    padding-right: 5px;
    padding-left:5px;
} */
.Submitbtn{
    text-align: center;
    margin: 2vw auto;
    
}
.Submitbtn Button {
    text-transform: uppercase;
    margin-bottom: 20px;
}


@media screen and (max-width: 575px) {

    /* .key{
        margin-left: 1vw;
        margin-top: 20px;
        font-size: 5vw;
    } */

    .container{
      width: 100%;
        /* width: fit-content; */
    }
    .input{
        padding-left: 0px;
        padding-right: 0px;
    }
            /* .heading{
        font-size: 6vw;
    } */
}

@media screen and (min-width: 500px){
        /* .input{
        padding-left: 20px;
        padding-right: 20px;
    } */

}

@media screen and (min-width:576px){

        .container{
      width:70%;
        /* width: fit-content; */
    }

}

@media screen and (min-width:850px){
/* 
  .key{
      margin-top: 0px;
      
  } */
  .input{
      margin-top: 10px;
  }

          .container{
      width:50%;
        /* width: fit-content; */
    }

}
@media screen and (min-width:1077px){
      /* .key{
     
      font-size: 1.8vw;
  } */
}

@media screen and (min-width:1250px){
  /* .key{
      margin-top: 0px;
      font-size: 1.6vw;
  } */
  .input{
      margin-top: 10px;
  }
            /* .heading{
        font-size: 3vw;
    } */
}

/* @media screen and (max-width:998px){
    .heading{
        font-size: 6vw;
    }
} */



