.help__form__container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    place-items: center;
    min-height: 85vh;
    padding: 0px 40px;

}


.HelpForm1 {
    height: fit-content;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* max-width: 80%; */

}

.HelpForm1__header {
    text-align: center;
    font-size: 30px;
    color: #3743B1;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;

    padding: 10px;
    max-width: 90%;
    line-height: normal;
    background-color: #fff;
    /* border-bottom: 1px solid #ccc; */
    /* border-top: 3px solid #28328c ; */
}

.HelpForm1__body{
    /* padding: 60px 10px; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.HelpForm1__card {
    cursor: pointer;
    flex: 1;
    height: 200px;
    /* border: 1px solid #ccc; */
    box-shadow: 0 0 5px rgba(55,67,177,.36);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin: 15px;
}

.HelpForm1__card > p {
    font-size: 20px;
    font-weight: 600;
    color: #3743B1;
}

.HelpForm1__card >span {
    color: #707070;
    font-size: 16px;
}

.help_svg_icons{
    transform: scale(2.4);
    margin: 30px;
    color: #28328c;
    width: 30px;
  }


  /* // form 2 */

  .HelpForm2 {
    height: fit-content;
    width: 100%;
    max-width: 590px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* max-width: 80%; */

}

  .HelpForm2__header {
    width: 100%;
    font-size: 20px;
    color: #3743B1;
    font-weight: 600;
    line-height: normal;
    padding: 20px 20px 0 20px;
    background-color: #fff;

  }

  .HelpForm2__body{
    /* padding: 10px 10px; */
    width: 100%;
    flex-direction: column;
    display: flex;
    flex: 1;
  }

  .select__btn {
      font-size: 17px;
    width: 210px;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: rgb(99, 98, 98);
    padding: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 10px;
    cursor: pointer;
  }

  .select__btn__active {
    border: 1px solid rgb(242, 125, 125);
    background-color: rgb(255, 231, 231);
  }

  .mg__rt {
      margin-right: 10px;
  }

  .help__next__btn {
    font-weight: 600;
    font-size: 18px;
    max-width: 240px;
    align-self: flex-end;
    background: #3743B1;
    color: whitesmoke;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    margin: 10px 0;
    border: none;
    outline: none !important;
}

.help__next__btn:hover {
  background-color: #fff;
  color: #3743B1;
  border-color: #3743B1;
  border: 1px;
  border-style: solid;
}

.HelpForm2__textarea {
  padding: 20px;
  font-size: 17px;
  color: rgb(99, 98, 98);
  font-weight: 600;
}

.HelpForm2__textarea > textarea {
  background-color: rgb(242, 242, 242) !important;
}


/* .HelpForm2__footer {
    transition: all 2s ease 1s;
} */


/* form 3 */

.HelpForm3__btn__ctr {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    padding-top: 20px;
}

.HelpForm3__btn__ctr > button {
    padding: 8px 15px 8px 18px;
    border-radius: 8px;
    color: rgb(70, 70, 70);
    font-weight: 600;
    margin: 10px;
    font-size: 16px;

}

.HelpForm_3_body > span {
color: grey;
font-size: 26px;
}

.HelpForm2__select__container{
padding:20px 0 40px 0;
}

.button_unselect {
  padding-left: 15px;
  /* padding-right: 8px; */
  font-size: small;
  border-radius: 50px;
  border: 1px !important;
  border-color: #3743B1 !important;
  background-color: #fff !important;
  color: #3743B1 !important;
  outline: none !important;
  border-bottom-color: #3743B1 !important;
}
.button__selected {
  padding-left: 15px;
  /* padding-right: 8px; */
  font-size: small;
  border-radius: 50px;
  border:1px !important;
  border-color: #3743B1 !important;
  background-color:#3743B1 !important;
  color: white !important;
  outline: none !important;
  border-bottom-color: #3743B1 !important;
  /* border: 2px solid rgb(252, 164, 164) !important; */
}

.remove_svg {
  transform: scale(0.7);
}

.basic-multi-select {
  padding: 0 20px;
}

.css-yk16xz-control {
  border-bottom: none !important;
  border: 1px solid #3743B1 !important;
  padding: 5px;
}

.css-yk16xz-control:hover {
  border-bottom: none !important;
  border: 1px solid #3743B1 !important;
  padding: 5px;
}

.select__menu {
  z-index: 1000 !important;
}

.select__control--menu-is-focused {
  border-bottom: none !important;
  border: 1px solid #3743B1 !important;
  padding: 5px;
}

.css-yk16xz-control:focus {
  border-bottom: none !important;
  border: 1px solid #3743B1 !important;
  padding: 5px;
}

.css-1rhbuit-multiValue {
  background-color: white !important;
  color: #3743B1 !important;
  border : 1px #3743B1 solid !important;
  border-radius: 50px !important;
}

.select__multi-value__remove {
  padding-right: 8px !important;
  
}

.select__multi-value__remove >svg {
  background-color: #3743B1 !important;
  border-radius: 15px !important;
  color: #fff !important;
}

.select__multi-value__remove >svg:hover {
  background-color: white !important;
  border-radius: 15px !important;
  color: #3743B1 !important;
  border: 1px;
  border-style: solid;
  border-color: #3743B1;
}

.select__multi-value__remove:hover {
  background-color: white !important;
  border-radius: 15px !important;
}


@media (max-width:480px) {
  .HelpForm1__header {
    
    margin-top: 80px;
    font-size: 24px;
  }

  .HelpForm1__body {
    flex-wrap: nowrap !important;
    flex-direction: column;
  }

  .HelpForm2 {
    margin-top: 100px !important;
  }
}
