.trainers__card {
  width: 100%;
  min-width: 330px;
  max-width: 350px;
  display: flex;
  min-height: 320px;

  /* max-width: 900px; */
  /* padding: 0 30px ; */
  /* margin : 13px 0; */

  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
}

.body__ctr1 {
  display: flex;
  margin-top: 50px;
  padding: 40px;
}

.card {
  /* flex-direction: row; */
  border-radius: 10px;
  border: none !important;
}

.trainers__card__top {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 0.85;
    font-size: 22px;
    line-height: 2em;
}

.profile_img {
    width:53px;
    object-fit: cover;
    height:53px;
    border-radius: 100%;
    margin: auto 0px;
    border:2px solid #3743B1
}

.trainers__card__details {
    /* padding: 10px 40px; */
    font-size: 20px;
}

.trainers__card__bottom {
    display: flex;
    flex: 0.15;
    padding: 20px;
    flex-direction: column;
    margin: auto 0px;
}

.trainers__card__bottom > button {
  /* margin: 5px; */
  /* height: 35px; */
  /* width: 130px; */
    font-weight: bold;
    border-color: #3743B1;
    color: #3743B1;
    font-weight: 500;
    background-color: white;
  
}

.trainers__card__bottom > button:hover {
  /* margin: 5px; */
  /* height: 35px; */
  /* width: 130px; */
    font-weight: bold;
    background-color: #3743B1;
    font-weight: 500;
    border-color: #3743B1;
}

.__card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .__card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .advance_Card {
    flex: 0.4;
    margin-top: 0;
  } 
  .advance_search_card {
    box-shadow: 0 3px 6px rgba(55, 67, 117, 0.36);
    min-height: 400px;
    border-radius: 10px;
    padding: 20px;
    max-width: 340px;

  }

  .help_reset_btn {
    font-weight: 600;
    font-size: 18px;
    max-width: 240px;
    align-self: flex-end;
    background: white;
    color: #3743B1;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    margin: 10px 0;
    border: 1px solid #3743B1;
    outline: none !important;
  }

  .help_reset_btn:hover {
    background: #3743B1;
    color: white;
  }


  @media (max-width:480px) {
    .advance_Card {
      margin-top: 60px !important;
    }
  }

  @media(max-width:900px) {
    .body__ctr1 {
      display: flex;
      flex-direction: column;
      column-gap: 100px;
      /* padding: 40px; */
    }
    .trainers__card {
       flex-direction: column;
        padding: 30px 8px ;
        margin : 20px 0;
        }

        .trainers__card__left {
            flex: 1;
            font-size: 19px;
            line-height: 2em;
            flex-direction: column;
            align-items: center;
        }

        .trainers__card__right {

            flex: 1;
            flex-direction: row;
            margin: 0px;
           justify-content: space-around;
        }

        /* .trainers__card__right > button {
            margin: 5px;
            height: 50px;
            width: 180px;
            font-weight: bold;

          } */
          .trainers__card__details {
            padding: 0px;
            /* text-align: center; */
        }
  }

  .btn_Chat {
    width: 76px !important;
    height: 35px !important;
    font-size: 10px !important;
    color: #3743B1 !important;
    border-radius: 10px !important;
  }

  .btn_Chat:hover {
    background-color: #3743B1 !important;
    color: white !important;
  }

  .btn-outline-info {
    border: 1px solid #3743B1 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }