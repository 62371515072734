.Spinner_Loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Profile {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.Profile__logo {
  position: absolute;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80px;
  margin-right: auto;
  margin-left: auto;
  transition: 0.3s ease;
  cursor: pointer;
  z-index: 1;
}


.Profile__container {
  width: 560px;
  margin-top: 5rem;
  background: white;
  box-shadow: 0 0 6px rgba(55,67,177,.15);
  /* border: 1px solid #dedfe0; */
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 5rem;
}

.Profile__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Profile__title h6 {
  color: rgb(172, 165, 165);
}

.profile__image {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.profile__image img {
  width: 96px;
  height: 98px;
  border-radius: 10px;
}

.profile__input_Fields {
  /* border: 1px solid #dedfe0; */
}

.alert {
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  text-align: center;
  height: 0;
  z-index: 1;
}

.alert p {
  padding: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert p button {
  margin-left: 10rem;
  color: red;
  background: transparent;
}

.alert.active {
  height: 50px;
}

@media only screen and (max-width: 900px) {
  .Profile__section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile__image {
    margin: 10px;
  }
  .Profile__logo {
    width: 50px;

    z-index: 1;
  }
}


.upload-image {
  background-color: white !important;
  border:1px !important;
  border-color: #3743B1 !important;
  border-style: solid !important;
  color: #3743B1 !important;
  font-size: small !important;

}

.upload-image:hover {
  background-color: #3743B1 !important;
  color: white !important
}