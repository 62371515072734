::-webkit-scrollbar {
    display: none;
}

html {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}

.home_container {
    display: flex;
    flex-direction: column;
}

/* Immortal mod */
.home_container > div > ol {
    bottom: -40px;
}
.home_container > div > ol > li {
    border : 1px;
    border-color: #3743B1 !important ;
    border-style: solid;
    width: 15px;
    height: 8px;
    border-radius: 10px;
}

.button__card__details > div > ol {
    bottom: -20px;
}

.button__card__details > div > ol > li {
    border : 1px;
    border-color: #3743B1 !important ;
    border-style: solid;
    width: 15px;
    height: 8px;
    border-radius: 10px;
}

.button__card__details > div > ol > li.active {
    background-color: #3743B1;
    width: 30px;
}

.home_container > div > ol > li.active {
    background-color: #3743B1;
    width: 30px;
}

/* ------------ */


.button__container{
display: flex;
justify-content: space-between;
padding: 56px 40px 23px 40px;
gap: 20px;
}


/* immortal ui mod */
.button__card {
    display: flex;
    padding: 10px 20px;
    box-shadow: 0 0 10px 0 rgba(55,67,177,0.7);
    transition: 0.3s;
    border-radius: 20px;
    min-height: 244px;
    justify-content: center;
    align-items: center;
    flex: 0.47;
}
.button__card:hover {
    box-shadow: 0 8px 16px 0 rgba(55,67,177,0.6);
  }

.button__card__img{
    flex:0.3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.button__card__img > img{
    /* width: 100%; */
    width: 115px;
}
.button__card__details{
    flex:0.7;
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 20px 10px;
}
.button__card__details > p{
 font-size: 16px;

}
.button__card__details > h2{
    font-size: 22px;
    font-weight: 500;
   }
.button__card__details> div > button {
    width: 200px;
    height: 43px;
    padding: 5px;
    border-radius: 6px;
    background-color: white;
    border: 1px solid #3743B1 !important;
    color: #3743B1;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer !important;
    pointer-events: auto;
}

.button__card__details> div > button:hover {
    color: whitesmoke;
    background-color: #3743B1;
    
}

.cards__container {
     display: flex;
     gap: 20px;
     padding: 50px 40px 50px 40px;
     background-color: #3743B1;
     justify-content: space-between;
}
._card {
    flex: 0.3;
    color: rgb(223, 222, 222);
    padding: 3px;
    border-radius: 30px;
    font-size: 20px;
    line-height: 1.5em;
    border: 1px;
    border-color: white;
    border-style: solid;
}
._card > img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    /* width: 100%; */
    border-radius: 35px !important;
    /* height: 352px; */
    
    border-radius: 10px;
}
._card > h3{
    padding: 8px 10px;
    font-size: 30px;
    color: whitesmoke;
}

._card > p{
    color: whitesmoke;
    font-size: 18;
    padding: 0 10px;
}

.loginButton:hover {
    background-color: #3743B1 !important;
    color: white !important;
}

.signupButton:hover {
    border-color: #3743B1 !important;
    color:#3743B1 !important;
    background-color: white !important;
}

.loginButton:active {
    border-color: #3743B1 !important;
}
/* ----------------- */

._footer {
    display: flex;
    justify-content: space-around;
    background-color: #3743b1;
    padding: 40px 0;
    font-size: 20px;
    
}
.footer__cards {
    color: rgb(237, 237, 237);
    display: flex;
    flex: 0.3;
    align-items: center;
    flex-direction: column;
}
.footer__cards > span {
    color: rgb(214, 214, 214) !important;
    cursor: pointer;
    font-weight: 400;
}
.footer__cards > h3{
    padding-bottom: 4px;
    font-size: 30px;
    color: whitesmoke;
}
.footer__cards > span {
padding: 3px 0;
color: rgb(237, 237, 237);
}
.blog__container{
   padding: 0px 40px 30px;
   box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.css-1wa3eu0-placeholder {
    color: #3743B1 !important;
}
.rating i.filled {
	font-size: 17px !important;
}
.product .rating i {
	font-size: 17px !important;
}

@media (max-width: 480px) {
    .blog_mobile_prev {
        flex-direction: column !important;
    }
    .blog_mobile_prev_img {
        width: 100% !important;
    }
    .blog_mobile_prev_blog {
        margin: 0 !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        padding: 20px !important;
    }
    .MuiFormLabel-root {
        font-size: small !important;
    }
}

@media(max-width: 1000px) {
    .button__container {
        flex-direction: column;
    }
}

@media(max-width: 720px) {
    ._footer{
        flex-direction: column;
    }

    .footer__cards {
        margin-bottom: 10px;
    } 
    
    .cards__container {
        flex-direction: column;
    }

  
   
}

@media(max-width: 500px) {
    .button__card {
        display: inline;}

    .button__card__details{
        font-size: 12px;
    }
    .button__card__details> button {
        width: 100px;
        height: 30px;
        font-size: 13px;
    }
 
    ._card > img{
        width: "100%"
    }

}

.socials a{
    text-decoration: none;
    color: inherit;
}
/*
Author       : Dreamguys
Template Name: Mentoring - Bootstrap Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Bootstrap Classes
4. Avatar
5. Select2
6. Nav Tabs
7. Modal
8. Components
10. Focus Label
11. Header
12. Mobile Menu
13. Footer
14. Login
15. Home
16. Search
17. Profile
18. Booking
19. Checkout
20. Booking Success
21. Invoice View
22. Schedule Timings
23. Mentor Dashboard
24. Mentor Profile
25. Add Billing
26. Chat
27. Mentor Profile Settings
28. Calendar
29. Mentee Dashboard
30. Profile Settings
31. Appoitment List
32. Reviews
33. Voice call
34. Video Call
35. Outgoing Call
36. Incoming Call
37. Terms and Conditions
38. Blog
39. Blog Details
40. Blog Grid
41. Map List & Grid
42. Loader
43. Dashboard
44. Responsive

========================================*/

/*-----------------
	1. General
-----------------------*/

/* @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,900');
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Regular.eot);  */
    /* For IE6-8 */
	/* src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url(../fonts/MaterialIcons-Regular.html) format('woff2'),
	url(../fonts/MaterialIcons-Regular.woff) format('woff'),
	url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
} */
.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}
html {
    height: 100%;
}
body {
	background-color: #fff;
	color: #26292c;
    font-family: "Poppins",sans-serif;
	font-size: .875rem;
    height: 100%;
	overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
	color: #26292c;
	font-weight: 500;
}
.h1, h1 {
    font-size: 2.25rem;
}
.h2, h2 {
    font-size: 1.875rem;
}
.h3, h3 {
    font-size: 1.5rem;
}
.h4, h4 {
    font-size: 1.125rem;
}
.h5, h5 {
    font-size: 1rem;
}
.h6, h6 {
    font-size: 0.875rem;
}
a {
    color: #26292c;
}
a:hover {
	color: #1e88e5;
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
button:focus {
	outline: 0;
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.btn.focus, .btn:focus {
	box-shadow: inherit;
}
.form-control {
    border-color: #dce0eb;
    color: #26292c;
	font-size: 15px;
    min-height: 42px;
	padding: 6px 15px;
}
.table .form-control {
	font-size: 14px;
	min-height: 38px;
}
.container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}
.form-control::-webkit-input-placeholder {
	color: #6e82a3;
	font-size: 14px;
}
.form-control::-moz-placeholder {
	color: #6e82a3;
	font-size: 14px;
}
.form-control:-ms-input-placeholder {
	color: #6e82a3;
	font-size: 14px;
}
.form-control::-ms-input-placeholder {
	color: #6e82a3;
	font-size: 14px;
}
.form-control::placeholder {
	color: #6e82a3;
	font-size: 14px;
}
.list-group-item {
	border: 1px solid #f0f0f0;
}
.content {
	min-height: 200px;
	padding: 30px 0 0;
}

/*-----------------
	2. Table
-----------------------*/

.table {
	color: #26292c;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table > thead > tr > th {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}
.table.dataTable {
	border-collapse: collapse !important;
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2 a {
	color: #26292c;
}
table.table td h2 a:hover {
	color: #1879cd;
}
table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead {
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.table thead tr th {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr:last-child {
	border-color: transparent;
}
.table.table-center td,
.table.table-center th {
	vertical-align: middle;
}
.table-hover tbody tr:hover {
	background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body {
    padding: 0;
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .table tr td:first-child,
.card-table .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .table tr td:last-child,
.card-table .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
}

/*-----------------
	3. Bootstrap Classes
-----------------------*/

.btn.focus, .btn:focus {
    box-shadow: unset;
}
.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #26292c;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary,
.badge-primary {
	background-color: #1e88e5 !important;
}
a.bg-primary:focus, 
a.bg-primary:hover, 
button.bg-primary:focus, 
button.bg-primary:hover {
    background-color: #1879cd !important;
}
.bg-success,
.badge-success {
	background-color: #00e65b !important;
}
a.bg-success:focus, 
a.bg-success:hover, 
button.bg-success:focus, 
button.bg-success:hover {
    background-color: #00cc52 !important;
}
.bg-info,
.badge-info {
	background-color: #009efb !important;
}
a.bg-info:focus, 
a.bg-info:hover, 
button.bg-info:focus, 
button.bg-info:hover {
    background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}
a.bg-warning:focus, 
a.bg-warning:hover, 
button.bg-warning:focus, 
button.bg-warning:hover {
    background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #ff0100 !important;
}
a.bg-danger:focus, 
a.bg-danger:hover, 
button.bg-danger:focus, 
button.bg-danger:hover {
    background-color: #e63333 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #1e88e5 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #00cc52 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #ff0100 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
    color: #757575 !important;
}
.btn-primary {
	background-color: #1e88e5;
	border: 1px solid #1e88e5;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #1879cd;
	border: 1px solid #1879cd;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #1879cd;
	border: 1px solid #1879cd;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #1879cd;
	border-color: #1879cd;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled), 
.btn-primary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: #1e88e5;
    border-color: #1e88e5;
    color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled), 
.btn-secondary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #00e65b;
	border: 1px solid #00e65b
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #00cc52;
	border: 1px solid #00cc52;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #00cc52;
	border: 1px solid #00cc52
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #00cc52;
	border-color: #00cc52;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled), 
.btn-success:active:focus:not(:disabled):not(.disabled), 
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled, .btn-success:disabled {
    background-color: #00e65b;
    border-color: #00e65b;
    color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled), 
.btn-info:active:focus:not(:disabled):not(.disabled), 
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled, .btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled), 
.btn-warning:active:focus:not(:disabled):not(.disabled), 
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff;
}
.btn-danger {
	background-color: #ff0100;
	border: 1px solid #ff0100;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e63333;
	border-color: #e63333;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled), 
.btn-danger:active:focus:not(:disabled):not(.disabled), 
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled, .btn-danger:disabled {
    background-color: #f62d51;
    border-color: #f62d51;
    color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled), 
.btn-light:active:focus:not(:disabled):not(.disabled), 
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled), 
.btn-dark:active:focus:not(:disabled):not(.disabled), 
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-outline-primary {
	color: #1e88e5;
	border-color: #1e88e5;
}
.btn-outline-primary:hover {
	background-color: #1e88e5;
	border-color: #1e88e5;
}
.btn-outline-primary:focus, 
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled, 
.btn-outline-primary:disabled {
	color: #1e88e5;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #1e88e5;
	border-color: #1e88e5;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:hover {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #00e65b;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, 
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, 
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	background-color: transparent;
	color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active, 
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, 
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, 
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, 
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	background-color: transparent;
	color: #ff0100;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, 
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, 
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ababab;
}
.pagination > .active > a, 
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #1e88e5;
	border-color: #1e88e5;
}
.pagination > li > a, 
.pagination > li > span {
	color: #1e88e5;
}
.page-link:hover {
	color: #1e88e5;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #1e88e5;
	border-color: #1e88e5;
}
.dropdown-menu {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    box-shadow: inherit;
	font-size: 14px;
    transform-origin: left top 0;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #0de0fe;
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.card {
    border: 1px solid #e3e8eb;
    margin-bottom: 1.875rem;
    border-radius: 0;
}
.card-body {
	padding: 1.5rem;
}
.card-title {
    margin-bottom: 15px;
}
.card-header {
	border-bottom: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}
.card .card-header .card-title {
    margin-bottom: 0;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active, 
.bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #1e88e5;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today::before{
	border-bottom-color: #1e88e5;
}
.bg-info-light {
	background-color: rgba(2, 182, 179,0.12) !important;
	color: #1db9aa !important;
}
.bg-primary-light {
	background-color: rgba(17, 148, 247,0.12) !important;
	color: #2196f3 !important;
}
.bg-danger-light {
	background-color: rgba(242, 17, 54,0.12) !important;
	color: #e63c3c !important;
}
.bg-warning-light {
	background-color: rgba(255, 152, 0,0.12) !important;
	color: #f39c12 !important;
}
.bg-success-light {
	background-color: rgba(15, 183, 107,0.12) !important;
	color: #26af48 !important;
}
.bg-purple-light {
	background-color: rgba(197, 128, 255,0.12) !important;
	color: #c580ff !important;
}
.bg-default-light {
	background-color: rgba(40, 52, 71,0.12) !important;
	color: #283447 !important;
}
.text-xs {
	font-size: .75rem !important;
}
.text-sm {
	font-size: .875rem !important;
}
.text-lg {
	font-size: 1.25rem !important;
}
.text-xl {
	font-size: 1.5rem !important;
}
.form-control:focus {
	border-color: #bbb;
	box-shadow: none;
	outline: 0 none;
}

/*-----------------
	4. Avatar
-----------------------*/

.avatar {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 3rem
}
.avatar > img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #1e88e5;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25%;
	height: 25%;
	border-radius: 50%;
	content: '';
	border: 2px solid #fff;
}
.avatar-online::before {
	background-color: #00e65b;
}
.avatar-offline::before {
	background-color: #ff0100;
}
.avatar-away::before {
	background-color: #ffbc34;
}
.avatar .border {
	border-width: 3px !important;
}
.avatar .rounded {
	border-radius: 6px !important;
}
.avatar .avatar-title {
	font-size: 18px;
}
.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
}
.avatar-xs .border {
	border-width: 2px !important;
}
.avatar-xs .rounded {
	border-radius: 4px !important;
}
.avatar-xs .avatar-title {
	font-size: 12px;
}
.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
	border-width: 1px;
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
}
.avatar-sm .border {
	border-width: 3px !important;
}
.avatar-sm .rounded {
	border-radius: 4px !important;
}
.avatar-sm .avatar-title {
	font-size: 15px;
}
.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
	border-width: 2px;
}
.avatar-lg {
	width: 3.75rem;
	height: 3.75rem;
}
.avatar-lg .border {
	border-width: 3px !important;
}
.avatar-lg .rounded {
	border-radius: 8px !important;
}
.avatar-lg .avatar-title {
	font-size: 24px;
}
.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
	border-width: 3px;
}
.avatar-xl {
	width: 5rem;
	height: 5rem;
}
.avatar-xl .border {
	border-width: 4px !important;
}
.avatar-xl .rounded {
	border-radius: 8px !important;
}
.avatar-xl .avatar-title {
	font-size: 28px;
}
.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
	border-width: 4px;
}
.avatar-xxl {
	width: 5.125rem;
	height: 5.125rem;
}
.avatar-xxl .border {
	border-width: 6px !important;
}
.avatar-xxl .rounded {
	border-radius: 8px !important;
}
.avatar-xxl .avatar-title {
	font-size: 30px;
}
.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
	border-width: 4px;
}
.avatar-group {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.avatar-group .avatar + .avatar {
	margin-left: -.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
	margin-left: -.40625rem;
}
.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
	margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
	margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
	z-index: 1;
}

/*-----------------
	5. Select2
-----------------------*/

.select2-results__option {
	padding: 6px 15px;
}
.select2-container .select2-selection--single {
	border: 1px solid #dcdcdc;
	height: 46px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 44px;
	right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #dcdcdc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #dcdcdc;
	border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #26292c;
	font-size: 15px;
	font-weight: normal;
	line-height: 44px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #1e88e5;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #dcdcdc;
	min-height: 46px;
}
.select2-dropdown {
	border-color: #dcdcdc;
}

/*-----------------
	6. Nav Tabs
-----------------------*/

.nav-tabs {
	border-bottom: 1px solid #dce0eb;
}
.card-header-tabs {
    border-bottom: 0;
}
.nav-tabs > li > a {
	margin-right: 0;
	color: #555;
	border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	border-color: transparent;
	color: #26292c;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #26292c;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
	background-color: #1e88e5;
	border-color: #1e88e5;
	color: #fff;
}
.tab-content {
	padding-top: 30px;
}
.nav-tabs .nav-link {
	border-radius: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: transparent;
	color: #26292c;
}
.nav-tabs.nav-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #26292c;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
	background-color: #1e88e5;
	border-color: #1e88e5;
	color: #fff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
	border-radius: 50px;
}
.nav-tabs-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li+li > a {
	margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
	border-top-color: #1e88e5;
}
.nav-tabs.nav-tabs-bottom > li > a.active, 
.nav-tabs.nav-tabs-bottom > li > a.active:hover, 
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 2px;
	border-color: transparent;
	border-bottom-color: #1e88e5;
	background-color: transparent;
	transition: none 0s ease 0s; 
	-moz-transition: none 0s ease 0s; 
	-o-transition: none 0s ease 0s; 
	-ms-transition: none 0s ease 0s; 
	-webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
	background-color: #fafafa;
	border: 0;
}
.nav-tabs.nav-tabs-solid > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
	background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
	background-color: #f5f5f5;
	border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}

/*-----------------
	7. Modal
-----------------------*/

.modal {
	-webkit-overflow-scrolling: touch;
}
.modal-footer.text-center {
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.modal-footer.text-left {
	justify-content: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
}
.modal-dialog.modal-md {
    max-width: 600px;
}
.custom-modal .modal-content {
	border: 0;
    border-radius: 10px;
}
.custom-modal .modal-header {
    padding: 1.25rem;
}
.custom-modal .modal-footer {
    padding: 1.25rem;
}
.custom-modal .modal-body {
    padding: 1.25rem;
}
.custom-modal .close {
    background-color: #a0a0a0;
    border-radius: 50%;
    color: #fff;
    font-size: 17px;
    height: 20px;
    line-height: 20px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 26px;
    width: 20px;
    z-index: 99;
}
.custom-modal .modal-title {
	font-size: 20px;
}
.modal-backdrop.show {
    opacity: 0.4;
	-webkit-transition-duration: 400ms;
	transition-duration: 400ms;
}
.modal .card {
	box-shadow: unset;
}

/*-----------------
	8. Components
-----------------------*/

.comp-header {
    margin-bottom: 1.875rem;
}
.comp-header .comp-title {
    color: #26292c;
}
.line {
    background-color: #1e88e5;
    height: 2px;
    margin: 0;
    width: 60px;
}
.comp-buttons .btn {
	margin-bottom: 5px;
}
.pagination-box .pagination {
	margin-top: 0;
}
.comp-dropdowns .btn-group {
	margin-bottom: 5px;
}
.progress-example .progress {
	margin-bottom: 1.5rem;
}
.progress-xs {
	height: 4px;
}
.progress-sm {
	height: 15px;
}
.progress.progress-sm {
	height: 6px;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 18px;
}

/*-----------------
	10. Focus Label
-----------------------*/

.form-focus {
	height: 54px;
	position: relative;
}
.form-focus .focus-label {
	font-size: 16px;
	font-weight: 400;
	pointer-events: none;
	position: absolute;
	-webkit-transform: translate3d(0, 22px, 0) scale(1);
	-ms-transform: translate3d(0, 22px, 0) scale(1);
	-o-transform: translate3d(0, 22px, 0) scale(1);
	transform: translate3d(0, 22px, 0) scale(1);
	transform-origin: left top;
	transition: 240ms;
	left: 15px;
	top: -8px;
	z-index: 1;
	color: #b8b8b8;
	margin-bottom: 0;
}
.form-focus.focused .focus-label {
	opacity: 1;
	top: -32px;
	font-size: 13px;
	z-index: 1;
	background-color: #fff;
	left: 11px;
	padding: 0 7px;
}
.form-focus .form-control:focus ~ .focus-label, 
.form-focus .form-control:-webkit-autofill ~ .focus-label {
	opacity: 1;
	font-weight: 400;
	top: -32px;
	font-size: 13px;
	z-index: 1;
}
.form-focus .form-control {
	height: 54px;
	padding: 6px 17px 6px;
}
.form-focus input[type="password"] {
	font-size: 22px;
}
.form-focus .form-control::-webkit-input-placeholder {
	color: transparent;
	transition: 240ms;
}
.form-focus .form-control:focus::-webkit-input-placeholder {
	transition: none;
}
.form-focus.focused .form-control::-webkit-input-placeholder {
	color: #bbb;
}
.form-focus.select-focus .focus-label {
	opacity: 1;
	font-weight: 300;
	top: -20px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .select2-container .select2-selection--single {
	border: 1px solid #e3e3e3;
	height: 50px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 48px;
	right: 7px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ccc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ccc;
	border-width: 0 6px 6px;
}
.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 12px;
	padding-top: 10px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #676767;
	font-size: 14px;
	font-weight: normal;
	line-height: 38px;
}
.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #fc6075;
}

/*-----------------
	11. Header
-----------------------*/

.header-nav {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	border: 0;
	border-bottom: 1px solid #e3e8eb;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    position: relative;
	height: 70px;
	padding: 0 20px;
	margin-bottom: 0;
}
.main-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}
.main-nav > li.active > a {
	color: #1e88e5;
}
.main-nav > li .submenu li a {
    display: block;
    padding: 10px 15px;
    clear: both;
    white-space: nowrap;
    font-size: 14px;
    color: #26292c;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
	width: 100%;
	border-top: 1px solid #f0f0f0;
}
.main-nav > li .submenu > li.has-submenu > a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 13px;
}
.main-nav > li .submenu li {
    position: relative;
}
.main-nav li a {
	display: block;
    font-size: 15px;
    font-weight: 500;
	color: #26292c;
}
.main-nav li.login-link {
	display: none;
}
.logo {
    display: inline-block;
    margin-right: 30px;
    width: 160px;
}
.header-navbar-rht {
    margin: 0;
	margin-left: auto;
	padding: 0;
}
.header-navbar-rht li {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    padding-right: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.header-navbar-rht li:last-child {
	padding-right:0px;
}
.header-navbar-rht > li > a {
	font-size: 15px;
	font-weight: 500;
}
.header-navbar-rht li .dropdown-menu {
	border: 0;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
.header-navbar-rht .dropdown-toggle::after {
	display: none;
}
.header-navbar-rht li .dropdown-menu::before {
	content: "";
	position: absolute;
	top: 2px;
	right: 0;
	border: 7px solid #fff;
	border-color: transparent transparent #ffffff #ffffff;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
}
.header-navbar-rht li .dropdown-menu .dropdown-item {
	border-top: 1px solid #f0f0f0;
	padding: 10px 15px;
}
.header-navbar-rht li .dropdown-menu .dropdown-item:first-child {
	border-top: 0;
	border-radius: 5px 5px 0 0;
}
.header-navbar-rht li .dropdown-menu .dropdown-item:last-child {
	border-radius: 0 0 5px 5px;
}
.header-navbar-rht li a.header-login:hover {
    background-color: #1e88e5;
    border-color: #1e88e5;
    color: #fff;
}
.header-navbar-rht li .dropdown-menu a:hover {
    color: #1879cd;
	letter-spacing: 0.5px;
	padding-left: 20px;
	background-color: #fff;
}
.header-navbar-rht li a.header-login {
	background-color: rgba(30, 136, 229, 0.2);
	color: #1e88e5;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #1e88e5;
    text-transform: uppercase;
    font-weight: 500;
}
.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #757575;
	border-right: 2px solid #757575;
	content: '';
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
	margin-left: 10px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
}
.user-menu.nav > li > a {
	color: #fff;
	font-size: 14px;
	line-height: 58px;
	padding: 0 15px;
	height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #fff;
}
.user-img {
    display: inline-block;
    position: relative;
}
.user-img > img {
	height: 31px;
    object-fit: cover;
	width: 31px;
}
.user-menu.nav > li > a.mobile_btn {
	border: 0;
	position: relative;
	padding: 0;
	margin: 0;
	cursor: pointer
}
.header-navbar-rht .dropdown-menu {
    min-width: 200px;
    padding: 0;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
    padding: 7px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    border-top: 1px solid #e3e3e3;
    padding: 10px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item:hover {
    color: #1879cd;
}
.user-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    padding: 10px 15px;
}
.user-header .user-text {
	margin-left: 10px;
}
.user-header .user-text h6 {
	font-size: 15px;
    margin-bottom: 2px;
}
.header-navbar-rht .logged-item .nav-link {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: 14px;
	line-height: 70px;
	padding: 0 10px;
}

/*-----------------
	12. Mobile Menu
-----------------------*/

.sidebar-overlay {
	background-color: rgba(0, 0, 0, 0.6);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1040;
}
.menu-opened .main-menu-wrapper {
    transform: translateX(0);
}
.menu-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
    height: 60px;
    padding:  0 20px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    border-bottom: 1px solid #f0f0f0;
	display: none;
}
.menu-logo img {
    height: 40px;
}
.menu-close {
	font-size: 18px;
}
.bar-icon {
    display: inline-block;
    width: 31px;
}
.bar-icon span {
    background-color: #1e88e5;
    display: block;
    float: left;
    height: 3px;
    margin-bottom: 7px;
    width: 31px;
    border-radius: 2px;
}
.bar-icon span:nth-child(2) {
    width: 16px;
}
.bar-icon span:nth-child(3) {
    margin-bottom: 0;
}
#mobile_btn {
	display: none;
	margin-right: 30px;
}
html.menu-opened body {
    overflow: hidden;
}

/*-----------------
	13. Footer
-----------------------*/

.footer {
    background-color: #fff;
	border-top: 1px solid #eceff0;
	position: relative;
}
.footer:after{
	content: "";
	position: absolute;
	left: 0;
	bottom:0;
	width: 100%;
	height: 253px;
	background-image: url(../../assets/img/footer-bg.png);
	background-repeat: repeat;
	background-position: center;
}
.footer .footer-top {
	padding-top: 40px;
	position: relative;
	z-index: 9;
}
/* .footer .footer-top .footer-about-content {
	max-width: 315px;
} */
.footer .footer-bottom {
	position: relative;
	z-index: 9;
}
.footer-title {
    color: #26292c;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: capitalize;
}
.footer .footer-widget.footer-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.footer .footer-widget .footer-logo {
	margin-bottom: 15px;
}
.footer .footer-widget .footer-logo img {
    max-height: 50px;
    max-width: 100%;
}
.footer .footer-widget .footer-about-content p {
	color: #26292c;
}
.footer .footer-widget .footer-about-content p:last-child {
	margin-bottom: 0;
}
.footer .footer-menu ul li {
    margin-bottom: 10px;
    position: relative;
}
.footer .footer-menu ul li:last-child {
	margin-bottom: 0;
}
.footer .footer-menu ul li a {
	color: #26292c;
	transition: all 0.4s ease 0s;
}
.footer .footer-menu ul li a i {
	margin-right: 5px;
}
.footer .footer-widget.footer-menu ul li a:hover {
	color: #26292c;
	letter-spacing: 0.5px;
	padding-left: 10px;
}
.footer-contact-info {
	color: #26292c;
}
.footer-contact-info .footer-address {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.footer-contact-info .footer-address span {
	margin-right: 20px;
}
.footer-contact-info .footer-address span i.fa-map-marker-alt {
	font-size: 20px;
}
.footer-contact-info p i {
	margin-right: 15px;
}
.footer .footer-bottom .copyright {
    padding: 40px 0;
}
.home-page .footer .footer-bottom .copyright {
	border-top: 1px solid #eceff0;
}
.footer .footer-bottom .copyright-text p {
	color: #26292c;
}
.footer .footer-bottom .copyright-text p a {
	color: #09e5ab;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}
.footer .footer-bottom .copyright-text p a:hover {
	color: #26292c;
}
.footer .footer-bottom .copyright-text p.title {
	font-weight: 400;
	margin: 10px 0 0;
}
.footer .social-icon ul {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	list-style: none;
	padding: 0;
	margin: 0;
}
.footer .social-icon ul li {
	margin-right: 15px;
}
.footer .social-icon ul li:last-child {
	margin-right: 0;
}
.footer .social-icon ul li a {	
	color: #26292c;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 20px;
	transition: all 0.4s ease 0s;
}
.footer .social-icon ul li a:hover {
	color: #1e88e5;
}

/*-----------------
	14. Login
-----------------------*/

.account-page {
	background-color: #fff;
}
.account-page .content {
    padding: 50px 0;
}
.login-header {
	margin-bottom: 20px;
}
.login-header p {
	margin-bottom: 0;
}
.login-header h3 {
    font-size: 20px;
    margin-bottom: 0;
    text-transform: uppercase;
}
.login-header h3 span {
    color: #1e88e5;
}
.login-header h3 a {
    color: #1e88e5;
    float: right;
    font-size: 15px;
    margin-top: 2px;
}
.login-right .dont-have {
    color: #3d3d3d;
    margin-top: 20px;
    font-size: 14px;
}
.login-right .dont-have a {
    border-bottom: 1px dashed;
    color: #1e88e5;
}
.login-btn {
    font-size: 16px;
    font-weight: 500;
    display: block;
    width: 100%;
    color: #fff;
    padding: 8px 15px;
    border-radius: 5px;
    text-align: center;
}
.login-or {
	color: #d4d4d4;
	margin-bottom: 20px;
	margin-top: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
	position: relative;
}
.or-line {
	background-color: #e5e5e5;
	height: 1px;
	margin-bottom: 0;
	margin-top: 0;
	display: block;
}
.span-or {
	background-color: #fff;
	display: block;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	text-align: center;
	top: -3px;
	width: 42px;
}
.forgot-link {
    color: #3d3d3d;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 400;
    border-bottom: 1px solid;
}
.btn-facebook {
    background-color: #3a559f;
    color: #fff;
    font-size: 13px;
    padding: 8px 12px;
}
.btn-google {
    background-color: #dd4b39;
    color: #fff;
    font-size: 13px;
    padding: 8px 12px;
}
.social-login .btn:hover, .social-login .btn:focus {
	color: #fff;
}
.bg-pattern-style {
    background: url(../../assets/img/home_art.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    opacity: 1;
}
.bg-pattern-style:before {
	position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to right,rgba(255,255,255,.50) 20%,rgba(255,255,255,.01) 85%);
}
.bg-pattern-style .content {
	height: 100%;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}

/*-----------------
	15. Home
-----------------------*/

.section-search { 
	background: #f8f8f8;
    min-height: 450px;
    background-image: url(../../assets/img/banner.jpg);
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-blend-mode: Darken;
    padding: 110px 0;
}
.section-search:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0,0,0,0.56);
}
.section-header {
    margin-bottom: 30px;
    position: relative;
}
.section-header h2 {
    font-size: 36px;
    margin-bottom: 0;
    font-weight: 500;
    font-weight: bold;
	color: #26292c;
	letter-spacing: 0.9px;
}
.section-header .sub-title {
    color: #26292c;
    font-size: 16px;
    max-width: 600px;
    margin-top: 15px;
}
.section-header p {
    color: #26292c;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 15px;
}
.banner-wrapper {
    max-width: 962px;
    width: 100%;
    position: relative;
}
.banner-wrapper .banner-header {
	margin-bottom: 30px;
}
.banner-wrapper .banner-header h1 {
    margin-bottom: 15px;
	font-size: 50px;
	font-weight: bold;
	color: #ffffff;
}
.banner-wrapper .banner-header h1 span {
	color: #1e88e5;
}
.banner-wrapper .banner-header p {
	font-size: 24px;
	margin-bottom: 0;
	color: #fff;
}
.search-box form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
}
.search-box .form-group {
	margin-bottom: 0;
}
.search-box .form-control {
    border: 1px solid #e3e8eb;
    box-shadow: inset 0 0px 0px rgba(0,0,0,.075);
    border-radius: 50px;
    padding-left: 45px;
	min-height: 54px;
	box-shadow: 0 0 1.25rem rgba(31,45,61,.05);
	font-size: 16px;
}
.search-box .form-control::-webkit-input-placeholder {
	color: #6c757d;
	font-size: 16px;
}
.search-box .form-control::-moz-placeholder {
	color: #6c757d;
	font-size: 16px;
}
.search-box .form-control:-ms-input-placeholder {
	color: #6c757d;
	font-size: 16px;
}
.search-box .form-control::-ms-input-placeholder {
	color: #6c757d;
	font-size: 16px;
}
.search-box .form-control::placeholder {
	color: #6c757d;
	font-size: 16px;
}
.search-box .search-location {
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    margin-right: 12px;
    position: relative;
    width: 269px;
}
.search-location .form-control {
	background: #fff url(../../assets/img/location.png) no-repeat 17px center;
}
.search-box .search-info {
    -ms-flex: 0 0 490px;
    flex: 0 0 490px;
    margin-right: 12px;
    position: relative;
    width: 569px;
}
.search-info .form-control {
	background: #fff url(../../assets/img/search.png) no-repeat 15px 11px;
	padding-left: 60px;
}
.search-box .search-btn {
    width: 54px;
    -ms-flex: 0 0 54px;
    flex: 0 0 54px;
    height: 54px;
    border-radius: 100px;
}
.search-box .search-btn span {
	display: none;
	font-weight: 500;
}
.search-box .form-text {
    color: #757575;
    font-size: 13px;
}
.section-mentor {
	background-color: #f8f9fa;
	padding: 80px 0;
}
.section-mentor .section-header {
	margin-bottom: 30px;
}
.section-mentor .section-header p {
	margin-top: 10px;
}
.profile-widget {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius:0;
    margin-bottom:30px;
    position:relative;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
	padding: 0px;
}
.user-avatar {
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 0px;
}
.user-avatar img {
    border-radius: 0;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;
}
.user-avatar:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.profile-widget .fav-btn {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    width: 30px;
    height: 30px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    border-radius: 3px;
    color: #26292c;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    visibility: hidden;
	z-index: 99;
}
.profile-widget:hover .fav-btn {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.profile-widget .fav-btn:hover {
	background-color: #fb1612;
	color: #fff;
}
.pro-content {
    padding: 15px 15px;
}
.pro-content .title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
}
.profile-widget .pro-content .title a {
    display: inline-block;
}
.profile-widget .verified {
	color: #28a745;
	margin-left: 3px;
}
.profile-widget p.speciality {
    font-size: 13px;
    color: #757575;
    margin-bottom: 5px;
    min-height: 40px;
}
.rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
}
.rating i {
    color: #dedfe0;
}
.rating i.filled {
    color: #f4c150;
}
.profile-widget .rating {
    color: #757575;
    font-size: 14px;
    margin-bottom: 15px;
}
.profile-widget .rating i {
	font-size: 14px;
}
.available-info {
    font-size: 13px;
    color: #757575;
    font-weight: 400;
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
}
.available-info li + li {
	margin-top: 5px;
}
.available-info li i {
	width: 22px;
}
.row.row-sm {
    margin-left: -3px;
    margin-right: -3px;
}
.row.row-sm > div {
    padding-left: 3px;
    padding-right: 3px;
}
.view-btn {
    color: #1e88e5;
    font-size: 13px;
    border: 2px solid #1e88e5;
    text-align: center;
    display: block;
    font-weight: 500;
	padding: 6px;
}
.view-btn:hover, .view-btn:focus {
	background-color: #1879cd;
	border-color: #1879cd;
	color: #fff;
}
.book-btn {
	background-color: #1e88e5;
    border: 2px solid #1e88e5;
    color: #fff;
    font-size: 13px;
    text-align: center;
    display: block;
    font-weight: 500;
	padding: 6px;
}
.book-btn:hover, .book-btn:focus {
	background-color: #1879cd;
	border-color: #1879cd;
	color: #fff;
}
.section-mentor .profile-widget {
    box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
}
.about-content p {
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	margin: 0;
}
.about-content p + p {
	margin-top: 20px;
}
.about-content a {
    background-color: #0de0fe;
    border-radius: 4px;
	color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    min-width: 150px;
    padding: 15px 20px;
    text-align: center;
}
.about-content a:hover, .about-content a:focus {
	background-color: #01cae4;
	border-color: #01cae4;
	color: #fff;
}
.section-blogs {
    padding: 60px 0;
}
.section-blogs .section-header span {
	text-transform: inherit;
}
.section-blogs .grid-blog .blog-title {
	font-size: 16px;
}
.section-blogs .grid-blog .entry-meta li:first-child {
	margin-bottom: 3px;
}


.section-header span {
    color: #26292c;
    font-size: 20px;
    font-weight: 300;
    padding-bottom: 0px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.9px;
}
.section-header.text-center .sub-title {
    font-weight: 300;
    margin: 15px auto 0;
}

/*-----------------
	16. Search
-----------------------*/

.breadcrumb-bar {
    background-color: #f6f6f6;
	border-bottom: 1px solid #e3e8eb;
    padding: 15px 0;
}
.breadcrumb-bar .breadcrumb-title {
    font-size: 22px;
    font-weight: 700;
    margin: 5px 0 0;
}
.page-breadcrumb ol {
    background-color: transparent;
    font-size: 12px;
    margin-bottom: 0;
    padding: 0;
}
.page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
	font-size: 10px;
}
.sort-by {
    float: right;
}
.sort-title {
    font-size: 14px;
    margin-right: 10px;
}
.sortby-fliter {
	display: inline-block;
	width: 120px;
}
.cal-icon {
	position: relative;
	width: 100%;
}
.cal-icon:after {
	color: #979797;
    content: '\f073';
	display: block;
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px;
}
.custom_check {
	color: #666;
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-size: .9375rem;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.custom_check input {
	position: absolute;
	opacity: 0;
	cursor: pointer
}
.custom_check input:checked ~ .checkmark {
	background-color: #fff;
}
.custom_check .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #dcdcdc;
	background-color: #fff;
	border-radius: 3px;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 4px;
    top: 0;
    color: #1e88e5;
    font-size: 11px;
}
.custom_check input:checked ~ .checkmark:after {
	display: block
}
.custom_radio {
	color: #555;
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-size: 0.9375rem;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.radio_input .custom_radio + .custom_radio {
	margin-left: 15px;
}
.custom_radio input {
	position: absolute;
	opacity: 0
}
.custom_radio input:checked ~ .checkmark:after {
	opacity: 1
}
.custom_radio .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%
}
.custom_radio .checkmark:after {
	display: block;
	content: "";
	position: absolute;
	opacity: 0;
	top: 3px;
	left: 3px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #ff9b44;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.filter-widget {
	margin-bottom: 20px;
}
.filter-widget h4 {
	font-size: 1rem;
	margin-bottom: 15px;
}
.filter-widget .custom_check {
	line-height: 18px;
}
.btn-search .btn {
    background-color: #1e88e5;
    border: 1px solid #1e88e5;
    color: #fff;
    height: 46px;
	font-weight:500;
	font-size:16px;
}
.btn-search .btn:hover{
	background-color: #1879cd;
	border-color: #1879cd;
}
.mentor-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	-webkit-align-items: center;
	-ms-align-items: center;
}
.user-info-left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.user-info-right {
    margin-left: auto;
    -ms-flex: 0 0 210px;
    flex: 0 0 210px;
    max-width: 210px;
}
.mentor-img {
    -ms-flex: 0 0 145px;
    flex: 0 0 145px;
    margin-right: 20px;
    width: 145px;
}
.mentor-img img {
	border-radius: 6px;
}
.user-location {
    color: #757575;
	font-size: 14px;
    margin-bottom: 25px;
}
.user-location a {
    color: #1e88e5;
    font-weight: 500;
}
.mentor-widget .usr-name {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 3px;
}
.mentor-type {
    font-size: 14px;
    color: #757575;
    margin-bottom: 15px;
}
.mentor-widget .rating i {
	font-size: 14px;
}
.mentor-widget .average-rating {
	font-size: 14px;
	font-weight: 500;
}
.mentor-details {
	margin-bottom: 15px;
}
.mentor-details h5 {
    font-weight: normal;
    color: #757575;
    margin-bottom: 25px;
}
.mentor-details ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.mentor-details ul li {
	display: inline-block;
	padding-right: 5px;
}
.mentor-details ul li:last-child {
	padding-right: 0;
}
.mentor-details ul li a {
	display: inline-block;
}
.mentor-details ul li a img {
	border-radius: 5px;
	width: 40px;
}
.user-infos {
    margin-bottom: 15px;
}
.user-infos ul {
	font-size: 14px;
	list-style: none;
	margin: 0;
	padding: 0;
}
.user-infos ul li {
    display: block;
    line-height: 30px;
    color: #4E4852;
}
.user-infos ul li i {
    font-size: 15px;
    min-width: 30px;
}
.mentor-booking a + a {
	margin-top: 15px;
}
.mentor-booking a {
    background-color: #fff;
    border: 2px solid #1e88e5;
    border-radius: 4px;
    color: #1e88e5;
    display: block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 8px 15px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
.mentor-booking a.apt-btn {
    background-color: #1e88e5;
    color: #fff;
}
.mentor-booking a.apt-btn:hover, .mentor-booking a.apt-btn:focus {
	background-color: #1879cd;
	border-color: #1879cd;
	color: #fff;
}
.load-more {
	margin-bottom: 30px;
}

/*-----------------
	17. Profile
-----------------------*/

.mentor-action {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.mentor-action a + a {
	margin-left: 8px;
}
.mentor-action .fav-btn:hover {
	background-color: #fb1612;
	border-color: #fb1612;
	color: #fff;
}
.msg-btn:hover, .msg-btn:focus {
	background-color: #1e88e5;
	border-color: #1e88e5;
	color: #fff;
}
.call-btn:hover, .call-btn:focus {
	background-color: #1e88e5;
	border-color: #1e88e5;
	color: #fff;
}
.user-tabs .nav-tabs > li + li {
	margin-left: 30px;
}
.user-tabs .nav-tabs > li > a {
	border: 0;
	border-bottom: 3px solid transparent;
    color: #26292c;
    font-weight: 500;
    padding: 0 0 15px;
    font-size: 16px;
	text-transform: uppercase;
}
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active, 
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover, 
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 3px;
	color: #1e88e5;
}
.user-tabs .nav-tabs > li > a:hover {
	background-color: unset;
	color: #1e88e5;
}
.widget {
	margin-bottom: 30px;
}
.widget-title {
	margin-bottom: 15px;
	font-size: 20px;
}
.experience-box {
	position: relative;
}
.experience-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.experience-list > li {
	position: relative;
}
.experience-user .avatar {
	height: 32px;
	line-height: 32px;
	margin: 0;
	width: 32px;
}
.experience-list > li .experience-user {
	background: #fff;
	height: 10px;
	left: 4px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 4px;
	width: 10px;
}
.experience-list > li .experience-content {
	background-color: #fff;
	margin: 0 0 20px 40px;
	padding: 0;
	position: relative;
}
.experience-list > li .experience-content .timeline-content a.name {
	font-weight: 500;
}
.custom-border-card {
	border-bottom: 2px solid #1e88e5;
}
.experience-list > li .time {
	color: #757575;
	display: block;
	font-size: 13px;
}
.exp-year {
    color: #1e88e5;
    margin-bottom: 2px;
}
.exp-title {
    font-size: 16px;
}
.awards-widget .experience-list > li:last-child p {
	margin-bottom: 0;
}
.timings-days {
    font-weight: bold;
    color: #26292c;
    margin-bottom: 5px;
}
.timings-times span {
	display: block;
}
.review-listing {
    border-bottom: 1px solid #f5f7fc;
    margin-top: 20px;
    padding-bottom: 30px;
}
.review-listing > ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.review-listing > ul li + li {
	margin-top: 20px;
	border-top: 1px dashed #f0f0f0;
	padding-top: 20px;
}
.review-listing > ul li .comment {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    margin-bottom: 30px;
}
.review-listing > ul li .comment:last-child {
	margin-bottom: 0;
}
.review-listing > ul li .comment .comment-body {
    margin-left: 16px;
}
.review-listing > ul li .comment .comment-body .meta-data {
    position: relative;
    margin-bottom: 10px;
}
.review-listing > ul li .comment .comment-body .meta-data span {
    display: block;
    font-size: 16px;
    color: #757575;
}
.review-listing > ul li .comment .comment-body .meta-data span.comment-author {
    font-weight: 600;
    color: #26292c;
    text-transform: capitalize;
}
.review-listing > ul li .comment .comment-body .meta-data .review-count {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    position: absolute;
    top: 3px;
    right: 0;
    width: auto;
}
.review-listing > ul li .comment .comment-body .comment-content {
    color: #757575;
    margin-top: 15px;
    margin-bottom: 15px;
}
.review-listing > ul li .comment .comment-body .comment-reply .comment-btn {
    color: #1e88e5;
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
}
.review-listing .recommend-btn {
    float: right;
    color: #757575;
    font-size: 14px;
    padding: 5px 0;
	margin-bottom: 0;
}
.review-listing .recommend-btn a {
    border: 1px solid rgba(128,137,150,0.4);
    border-radius: 4px;
	display: inline-block;
    padding: 4px 12px;
    color: #757575;
    margin-left: 3px;
    margin-right: 3px;
    transition: all .3s;
}
.review-listing .recommend-btn a.like-btn:hover {
	background-color: #28a745;
	border: 1px solid #28a745;
	color: #fff;
}
.review-listing .recommend-btn a.dislike-btn:hover {
	background-color: #dc3545;
	border: 1px solid #dc3545;
	color: #fff;
}
.review-listing .recommend-btn a i {
    font-size: 16px;
}
.review-listing > ul li .comments-reply {
    list-style: none;
    margin-left: 65px;
    padding: 0;
}
.recommended {
    color: #28a745;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}
.all-feedback {
    margin-top: 20px;
}
.star-rating {
    direction: rtl;
}
.star-rating input[type=radio] {
    display: none
}
.star-rating label {
    color: #bbb;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}
.star-rating label:hover,
.star-rating label:hover ~ label,
.star-rating input[type=radio]:checked ~ label {
    color: #f2b600
}
.terms-accept a {
	color: #1e88e5;
	font-weight: 500;
}
.business-widget {
    background-color: #fcfcfc;
    border: 1px solid #f0f0f0;
    padding: 20px;
    margin-bottom: 0;
}
.listing-day {
	-webkit-box-align: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
    color: #000;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    margin-bottom: 10px;
}
.listing-day:last-child {
	margin-bottom: 0;
}
.listing-day.current {
    border-bottom: 1px solid #ddd;
    padding-bottom: 13px;
    margin-bottom: 13px;
}
.listing-day .day {
	font-weight: 500;
}
.listing-day.current .day {
	font-weight: bold;
}
.listing-day.current .day span {
	display: block;
	font-weight: normal;
}
.time-items {
    color: #757575;
}
.time-items > span {
    display: block;
    text-align: right;
}
.time-items > span.open-status {
	margin-bottom: 3px;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
	display: none;
}
.btn-blue{
	background-color: #1e88e5;
	border-radius: 50px;
	padding: 10px;
	color: #ffffff;
	width: 40px;
	height: 40px;
	text-align: center;
}
.btn-blue:hover {
	background-color: #1879cd;
	color: #fff;
}
.social-title {
	width: 100%;
	margin-bottom: 6px;
	font-size: 16px;
}
.blue-btn-radius {
	background-color: #1e88e5;
	border-radius: 50px;
	color: #fff;
	padding: 10px 40px;
	font-size: 18px;
	display: inline-block;
}
.blue-btn-radius:hover {
	background-color: #1879cd;
	color: #fff;
}
.hire-rate {
	color: #1e88e5;
	font-size: 24px;
	padding-bottom: 15px;
	display: inline-block;
	width: 100%;
}
.profile-custom-list > li {
	float: left;
	width: 50%;
}
.profile-custom-list > li .experience-content {
	margin-left: 0;
}
.row-result {
	font-weight: 700;
	padding-top: 5px;
}
.custom-about p {
	width: 100%;
	line-height: 1.8;
}
.course-box.blog.grid-blog {
	border: 1px solid #e0e0e0;
	border-radius: 0px;
}
.course-box.blog.grid-blog .blog-image {
	border-radius: 0 0 0 0;
	position: relative;
}
.course-box .view-box {
	position: absolute;
	right: 10px;
	top: -53px;
	width: 53px;
	height: 58px;
	background-color: #1e88e5;
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	line-height: 1.1;
}
.course-box .view-box span {
	font-size: 14px;
	font-weight: 400;
}
.course-box .view-box > div {
	padding-top: 12px;
}
.course-box .rate-box {
	position: absolute;
	right: 10px;
	top: 12px;
	background-color: #1e88e5;
	color: #fff;
	font-size: 13px;
	border-radius: 14px 0;
	padding: 5px 15px;
}
.course-content {
	padding: 20px;
	position: relative;
}
.course-content .date {
	font-size: 12px;
	color: #26292c;
	width: 100%;
	display: inline-block;
	opacity: 0.6;
}
.course-content p {
	font-size: 14px;
	color: #707070;
	line-height: 19px;
}
.course-title {
	font-size: 18px;
	color: #26292c;
	padding-bottom: 15px;
	display: inline-block;
}
.progress-bar-custom {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
}
.progress-bar-custom h6 {
	color: #555;
	font-size: 13px;
	text-align: left;
}
.pro-progress {
    text-transform: uppercase;
    background-color: #ddd;
    color: #555;
    position: relative;
    text-align: center;
    width: 100%;
    border-radius: 4px;
}
.pro-progress .tooltip {
  	background: #1e88e5;
  	font-size: 13px;
  	bottom: 100%;
  	color: #fff;
  	display: block;
  	margin-bottom: 9px;
  	opacity: 1;
  	padding: 0;
  	pointer-events: none;
  	position: absolute;
  	width: 20%;
  	border-radius: 6px;
  	width: 45px;
  	height: 31px;
  	text-align: center;
  	padding-top: 5px;
  	left:81%;
	z-index: 99;
}
.pro-progress .tooltip:before {
  	content: " ";
  	display: block;
  	height: 20px;
  	left: 0;
  	position: absolute;
  	width: 100%;
}  
.pro-progress .tooltip:after {
  	border-left: solid transparent 5px;
  	border-right: solid transparent 5px;
  	border-top: solid #1e88e5 5px;
  	bottom: -5px;
  	content: " ";
  	height: 0;
  	left: 50%;
  	margin-left: -7px;
  	position: absolute;
  	width: 0;
}
.pro-progress:hover .tooltip {
  	opacity: 1;
}
.pro-progress .tooltip-toggle {
  	width: 90%;
  	height: 8px;
  	background-color: #1e88e5;
  	line-height: 8px;
  	color: white;
  	text-align: center;
  	border-radius: 4px;
}

/*-----------------
	18. Booking
-----------------------*/

.booking-user-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.booking-user-info .booking-user-img {
    width: 80px;
    margin-right: 15px;
}
.booking-user-info .booking-user-img img {
    border-radius: 4px;
    height: 80px;
    width: 80px;
    object-fit: cover;
}
.schedule-widget {
    border-radius: 4px;
    min-height: 100px;
}
.schedule-header h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 3px 0 0;
}
.schedule-header {
    border-bottom: 1px solid #f0f0f0;
    border-radius: 4px 4px 0 0;
    padding: 10px 20px;
}
.day-slot ul {
    float: left;
    list-style: none;
    margin-bottom: 0;
    margin-left: -5px;
    margin-right: -5px;
    padding: 0;
    position: relative;
    width: 100%;
}
.day-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    width: 14.28%;
}
.day-slot li span {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
}
.day-slot li span.slot-date {
    display: block;
    color: #757575;
    font-size: 14px;
}
.day-slot li small.slot-year {
    color: #757575;
    font-size: 14px;
}
.day-slot li.left-arrow {
    left: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
}
.day-slot li.right-arrow {
    right: -11px;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px !important;
    transform: translateY(-50%);
}
.schedule-cont {
    padding: 20px;
}
.time-slot ul {
    list-style: none;
    margin-right: -5px;
    margin-left: -5px;
	margin-bottom: 0;
    padding: 0;
}
.time-slot li {
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    width: 14.28%;
}
.time-slot li .timing {
    background-color: #e9e9e9;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    color: #757575;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px 5px;
    text-align: center;
	position: relative;
}
.time-slot li .timing:hover {
    background-color: #fff;
}
.time-slot li .timing:last-child {
	margin-bottom: 0;
}
.time-slot li .timing.selected {
	background-color: #1e88e5;
	border: 1px solid #1e88e5;
	color: #fff;
}
.time-slot li .timing.selected::before {
    color: #fff;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
	font-size: 12px;
    font-weight: 900;
    position: absolute;
    right: 6px;
    top: 6px;
}
.submit-section.proceed-btn {
	margin: 0 0 30px;
}
.custom-sidebar-nav {
	width: 100%;
	position: relative;
}
.custom-sidebar-nav ul {
	list-style: none;
	padding:0;
	margin: 0;
}
.custom-sidebar-nav ul li {
	display: block;
	position: relative;
	padding: 12px 0;
	text-align: left;
}
.custom-sidebar-nav ul li:last-child {
	padding-bottom: 0;
}
.custom-sidebar-nav ul li a {
	color: #26292c;
	display: inline-block;
	font-size: 15px;
	width: 100%;
}
.custom-sidebar-nav ul li a:hover {
	color: #1e88e5;
	opacity: 1;
}
.custom-sidebar-nav ul li a.active {
	color: #1e88e5;
	opacity: 1; 
}
.custom-sidebar-nav ul li a i {
    margin-right: 5px;
    min-width: 23px;
}
.custom-sidebar-nav ul li a span {
	position: absolute;
	right: 0;
	top: 10px;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
.custom-sidebar-nav ul li a span i {
	margin-right: 0;
	min-width: inherit;
}
.custom-sidebar-nav ul li a:hover span {
	right: 15px;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}

/*-----------------
	19. Checkout
-----------------------*/

.info-widget {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.card-label > label {
    background-color: #fff;
    color: #959595;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin: 6px auto auto 8px;
    padding: 0 7px;
}
.card-label > input {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .05);
    display: block;
    height: 50px;
    margin-top: -13px;
    padding: 5px 15px 0;
    transition: border-color .3s;
    width: 100%;
}
.exist-customer a {
	color: #1e88e5;
	font-weight: 500;
}
.payment-widget .payment-list + .payment-list {
	margin-bottom: 15px;
}
.payment-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 600;
    color: #26292c;
    text-transform: capitalize;
}
.payment-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.payment-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    margin: 3px 0 0 0;
    border: 2px solid #ddd;
	border-top-color: rgb(221, 221, 221);
	border-right-color: rgb(221, 221, 221);
	border-bottom-color: rgb(221, 221, 221);
	border-left-color: rgb(221, 221, 221);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.payment-radio input:checked ~ .checkmark {
    border-color: #1e88e5;
}
.payment-radio .checkmark::after {
    position: absolute;
    left: 3px;
    top: 3px;
    content: '';
    width: 9px;
    height: 9px;
    background-color: #1e88e5;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.payment-radio input:checked ~ .checkmark::after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}
.booking-date {
    padding: 0;
    list-style: none;
}
.booking-date li {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #26292c;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.booking-date li span {
    float: right;
    color: #757575;
    font-weight: 400;
    font-size: 15px;
}
.booking-fee {
    padding: 0;
    list-style: none;
}
.booking-fee li {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #26292c;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.booking-fee li span {
    float: right;
    color: #757575;
    font-weight: 400;
    font-size: 15px;
}
.booking-total {
    border-top: 1px solid #e4e4e4;
    margin-top: 20px;
    padding-top: 20px;
}
.booking-total ul {
	padding: 0;
	list-style: none;
	margin: 0;
}
.booking-total ul li span {
    font-size: 18px;
    font-weight: 600;
    color: #26292c;
}
.booking-total ul li .total-cost {
    color: #1e88e5;
    font-size: 16px;
    float: right;
}

/*-----------------
	20. Booking Success
-----------------------*/

.success-page-cont {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display:flex;
}
.success-card .card-body {
	padding: 50px 20px;
}
.success-cont {
    text-align: center;
}
.success-cont i {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    color: #fff;
    width: 60px;
    height: 60px;
    border: 2px solid #1e88e5;
    border-radius: 50%;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    font-size: 30px;
    margin-bottom: 30px;
    background-color: #1e88e5;
}
.success-cont h3 {
    font-size: 24px;
}
.success-cont p {
    margin-bottom: 30px;
}
.success-cont strong {
    font-weight: 600;
}
.view-inv-btn {
	font-size: 16px;
	font-weight: 600;
	padding: 12px 30px;
}

/*-----------------
	21. Invoice View
-----------------------*/

.invoice-content {
    background-color: #fff;
	border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin-bottom: 30px;
	padding: 30px;
}
.invoice-item .invoice-logo {
    margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
    width: auto;
    max-height: 52px;
}
.invoice-item .invoice-text h2 {
	color:#26292c;
	font-size:36px;
	font-weight:600;
}
.invoice-item .invoice-details {
	text-align:right;
	color:#757575;
	font-weight:500
}
.invoice-item .invoice-details strong {
	color:#26292c
}
.invoice-item .invoice-details-two {
	text-align:left
}
.invoice-item .invoice-text {
	padding-top:42px;
	padding-bottom:36px
}
.invoice-item .invoice-text h2 {
	font-weight:400
}
.invoice-info {
	margin-bottom: 30px;
}
.invoice-info p {
	margin-bottom: 0;
}
.invoice-info.invoice-info2 {
    text-align: right;
}
.invoice-item .customer-text {
	font-size: 18px;
	color: #26292c;
	font-weight: 600;
	margin-bottom: 8px;
	display: block
}
.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
	color: #26292c;
	font-weight: 600;
	padding: 10px 20px;
	line-height: inherit
}
.invoice-table tr td,
.invoice-table-two tr td {
	color: #757575;
	font-weight: 500;
}
.invoice-table-two {
	margin-bottom:0
}
.invoice-table-two tr th,
.invoice-table-two tr td {
	border-top: 0;
}
.invoice-table-two tr td {
	text-align: right
}
.invoice-info h5 {
    font-size: 16px;
    font-weight: 500;
}
.other-info {
    margin-top: 10px;
}

/*-----------------
	22. Schedule Timings
-----------------------*/

.tab-content.schedule-cont .card-title {
	margin-bottom: 10px;
}
.user-times {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.user-slot-list {
	background-color: #d9534f;
	border: 1px solid #d43f3a;
	border-radius: 4px;
	color: #fff;
	font-size: 14px;
	margin: 10px 10px 0 0;
	padding: 6px 15px;
}
.user-slot-list a {
	color: #e48784;
	display: inline-block;
	margin-left: 5px;
}
.user-slot-list a:hover {
	color: #fff;
}
.schedule-nav .nav-tabs {
	border: 0 !important;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.schedule-nav .nav-tabs li {
	margin: 5px 15px 5px 0; 
	display: inline-block;
}
.schedule-nav .nav-tabs li:last-child {
	margin-right: 0;
}
.schedule-nav .nav-tabs > li > a {
	border: 1px solid #dcddea;
	border-radius: 4px;
	padding: 6px 15px;
	text-transform: uppercase;
}
.schedule-nav .nav-tabs li a.active {
	background: #1e88e5;
    border: 1px solid #1e88e5 !important;
	color: #fff;
}
.hours-info .form-control {
	min-height: auto;
}
.hours-info .btn.btn-danger.trash {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    height: 38px;
    width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.edit-link {
    color: #1e88e5;
    font-size: 16px;
    margin-top: 4px;
}

/*-----------------
	23. Mentor Dashboard
-----------------------*/

.dash-widget {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 20px;
	margin-bottom: 30px;
}
.circle-bar {
    margin-right: 15px;
}
.dash-widget h6 {
    font-size: 16px;
    font-weight: 400;
}
.dash-widget h3 {
    font-size: 24px;
    margin-bottom: 5px;
}
.dash-widget p {
	color: #757575;
    font-size: 14px;
	margin-bottom: 0;
}

/*-----------------
	24. Mentor Profile
-----------------------*/

.add-new-btn {
    background-color: #0de0fe;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 10px 20px;
}
.add-new-btn:focus, .add-new-btn:hover,.add-new-btn:active {
	background-color: #0de0fe;
    color: #fff;
}
.mentee-info {
	margin-top: 15px;
}
.mentee-info ul {
    padding: 0;
    list-style: none;
	font-size: .875rem;
	margin: 0;
}
.mentee-info ul li {
    position: relative;
    font-size: .875rem;
    font-weight: 500;
    color: #26292c;
    text-transform: capitalize;
}
.mentee-info ul li + li {
	margin-top: 15px;
}
.mentee-info ul li i {
	width: 18px;
}
.mentee-info ul li span {
    color: #757575;
    float: right;
    font-weight: 400;
}

/*-----------------
	26. Chat
-----------------------*/

.chat-page .footer {
	display: none;
}
.chat-window {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}
.chat-cont-left {
    border-right: 1px solid #f0f0f0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    left: 0;
    max-width: 35%;
    position: relative;
    z-index: 4;
}
.chat-cont-left .chat-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    color: #26292c;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    height: 72px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    padding: 0 15px;
}
.chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
}
.chat-cont-left .chat-header .chat-compose {
    color: #8a8a8a;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.chat-cont-left .chat-search {
    background-color: transparent;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    width: 100%;
}
.chat-cont-left .chat-search .avatar {
	flex: 0 0 3rem;
}
.chat-cont-left .chat-search .input-group {
    width: 100%;
}
.chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    border-radius: 50px;
    padding-left: 36px;
}
.chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: #ccc;
    box-shadow: none;
}
.chat-cont-left .chat-search .input-group .input-group-prepend {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    bottom: 0;
    color: #666;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
}
.chat-window .chat-scroll {
	min-height: 300px;
    max-height: calc(100vh - 305px);
    overflow-y: auto;
}
.chat-cont-left .chat-users-list {
    background-color: #fff;
}
.chat-window .chat-scroll .user-name{
	font-size: 16px;
	text-align: left;
	margin-top: 0;
}
.chat-cont-left .chat-users-list a.media {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 15px;
    transition: all 0.2s ease 0s;
}
.chat-cont-left .chat-users-list a.media:last-child {
	border-bottom: 0;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap {
    margin-right: 15px;
    position: relative;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
    height: 45px;
    width: 45px;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .status {
	bottom: 7px;
	height: 10px;
	right: 4px;
	position: absolute;
	width: 10px;
	border: 2px solid #fff;
}
.chat-cont-left .chat-users-list a.media .media-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
    color: #26292c;
    text-transform: capitalize;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    color: #8a8a8a;
    font-size: 14px;
    line-height: 24px;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child {
    text-align: right;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
    color: #8a8a8a;
    font-size: 13px;
}
.chat-cont-left .chat-users-list a.media:hover {
	background-color: #f5f5f6;
}
.chat-cont-left .chat-users-list a.media.read-chat .media-body > div:last-child .last-chat-time {
	color: #8a8a8a;
}
.chat-cont-left .chat-users-list a.media.active {
	background-color: #f5f5f6;
}
.chat-cont-right {
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
}
.chat-cont-right .chat-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    height: 72px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    padding: 0 15px;
}
.chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px;
}
.chat-cont-right .chat-header .media {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.chat-cont-right .chat-header .media .media-img-wrap {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 15px;
}
.chat-cont-right .chat-header .media .media-img-wrap .avatar {
	height: 50px;
	width: 50px;
}
.chat-cont-right .chat-header .media .media-img-wrap .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 3px;
    width: 10px;
}
.chat-cont-right .chat-header .media .media-body .user-name {
    color: #26292c;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
	margin-top: 0;
}
.chat-cont-right .chat-header .media .media-body .user-status {
    color: #666;
    font-size: 14px;
}
.chat-cont-right .chat-header .chat-options {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.chat-cont-right .chat-header .chat-options > a {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    border-radius: 50%;
    color: #8a8a8a;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    height: 30px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    margin-left: 10px;
    width: 30px;
}
.chat-cont-right .chat-body {
    background-color: #f5f5f6;
    max-height: calc(100vh - 264px);
    overflow: auto;
}
.chat-cont-right .chat-body ul.list-unstyled {
    margin: 0 auto;
    padding: 15px;
    width: 100%;
}
.chat-cont-right .chat-body .media .avatar {
    height: 30px;
    width: 30px;
}
.chat-cont-right .chat-body .media .media-body {
	margin-left: 20px;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div {
	padding: 10px 15px;
	border-radius: .25rem;
	display: inline-block;
	position: relative;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div p {
    color: #26292c;
    margin-bottom: 0;
}
.chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
	margin-top: 5px;
}
.chat-cont-right .chat-body .media.received {
	margin-bottom: 20px;
}
.chat-cont-right .chat-body .media:last-child {
	margin-bottom: 0;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div {
	background-color: #fff;
	border-radius: 14px 14px 14px 0;
}
.chat-cont-right .chat-body .media.sent {
    margin-bottom: 20px;
}
.chat-cont-right .chat-body .media.sent .media-body {
	-webkit-box-align: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
    margin-left: 0;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
    background-color: #1e88e5;
    border-radius: 14px 14px 0 14px;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
    color: #ffffff;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li a{
	color: #ffff;
}
.chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}
.chat-cont-right .chat-body .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 100%;
}
.chat-cont-right .chat-body .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    left: 50%;
    margin-left: 28px;
    position: absolute;
    top: 50%;
    width: 100%;
}
.chat-cont-right .chat-footer {
    background-color: #fff;
    border-top: 1px solid #f0f0f0;
    padding: 10px 15px;
    position: relative;
}
.chat-cont-right .chat-footer .input-group {
    width: 100%;
}
.chat-cont-right .chat-footer .input-group .form-control {
    background-color: #f5f5f6;
    border: none;
    border-radius: 50px;
}
.chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #f5f5f6;
    border: none;
    box-shadow: none;
}
.chat-cont-right .chat-footer .input-group .input-group-prepend .btn, 
.chat-cont-right .chat-footer .input-group .input-group-append .btn {
    background-color: transparent;
    border: none;
    color: #9f9f9f;
}
.chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
    background-color: #1e88e5;
    border-color: #1e88e5;
    border-radius: 6px;
    color: #fff;
    margin-left: 10px;
    min-width: 100px;
    font-size: 16px;
}
.msg-typing {
	width: auto;
	height: 24px;
	padding-top: 8px
}
.msg-typing span {
	height: 8px;
	width: 8px;
	float: left;
	margin: 0 1px;
	background-color: #a0a0a0;
	display: block;
	border-radius: 50%;
	opacity: .4
}
.msg-typing span:nth-of-type(1) {
	animation: 1s blink infinite .33333s
}
.msg-typing span:nth-of-type(2) {
	animation: 1s blink infinite .66666s
}
.msg-typing span:nth-of-type(3) {
	animation: 1s blink infinite .99999s
}
.chat-cont-right .chat-body .media.received .media-body .msg-box {
	position: relative;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative;
}
.chat-msg-info {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    clear: both;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
}
.chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative;
}
.chat-msg-info li:not(:last-child):after {
	position: absolute;
	right: 8px;
	top: 50%;
	content: '';
	height: 4px;
	width: 4px;
	background: #d2dde9;
	border-radius: 50%;
	transform: translate(50%, -50%)
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #aaa;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div .chat-time {
    color: rgba(50, 65, 72, 0.4);
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
    color: #ffffff;
}
.chat-msg-info li a {
	color: #777;
}
.chat-msg-info li a:hover {
	color: #2c80ff
}
.chat-seen i {
	color: #00d285;
	font-size: 16px;
}
.chat-msg-attachments {
	padding: 4px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin: 0 -1px
}
.chat-msg-attachments > div {
	margin: 0 1px
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info {
    flex-direction: row-reverse;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-attachments {
	flex-direction: row-reverse
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}
.chat-attachment img {
    max-width: 100%;
}
.chat-attachment {
	position: relative;
	max-width: 130px;
	overflow: hidden;
}
.chat-attachment {
	border-radius: .25rem;
}
.chat-attachment:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000;
	content: "";
	opacity: 0.4;
	transition: all .4s;
}
.chat-attachment:hover:before {
	opacity: 0.6;
}
.chat-attach-caption {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	padding: 7px 15px;
	font-size: 13px;
	opacity: 1;
	transition: all .4s;
}
.chat-attach-download {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all .4s;
	color: #fff;
	width: 32px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.2);
	text-align: center;
}
.chat-attach-download:hover {
	color: #495463;
	background: #fff;
}
.chat-attachment:hover .chat-attach-caption {
	opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
	opacity: 1;
}
.chat-attachment-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: -5px;
}
.chat-attachment-list li {
	width: 33.33%;
	padding: 5px;
}
.chat-attachment-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 5px solid rgba(230, 239, 251, 0.5);
	height: 100%;
	min-height: 60px;
	text-align: center;
	font-size: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
	opacity: 1;
}
.chat-msg-actions {
	position: absolute;
	left: -30px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	transition: all .4s;
	z-index: 2;
}
.chat-msg-actions > a {
    padding: 0 10px;
    color: #495463;
    font-size: 24px;
}
.chat-msg-actions > a:hover {
	color: #2c80ff;
}

@keyframes blink {
	50% {
		opacity: 1
	}
}
.btn-file {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    overflow: hidden;
    padding: 0 0.75rem;
    position: relative;
    vertical-align: middle;
}
.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.fill-blue{
	background-color: #009EFB !important;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	float: right;
	width: 19px;
	height: 19px;
}
.chat-window .chat-header{
	color: #009EFB;	
}

/*-----------------
	27. Mentor Profile Settings
-----------------------*/

.profile-image img {
	margin-bottom: 1.5rem;
}
.change-photo-btn {
    background-color: #1e88e5;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 15px;
    position: relative;
    transition: .3s;
    text-align: center;
    width: 220px;
}
.change-photo-btn input.upload {
	bottom: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
	left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
	width: 220px;
}
.dropzone {
    background-color: #fbfbfb;
    border: 2px dashed rgba(0, 0, 0, 0.1);
}
.btn-icon {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    text-align: center;
    position: absolute;
    padding: 0;
    font-size: 10px;
    width: 20px;
    height: 20px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    right: 3px;
    top: 3px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.upload-images {
    position: relative;
    width: 80px;
}
.upload-images img {
	border-radius: 4px;
	height: 80px;
	width: auto;
}
.upload-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.upload-wrap .upload-images + .upload-images {
	margin-left: 20px;
}
.btn.btn-danger.trash {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    height: 46px;
    width: 46px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.add-more a {
	color: #1e88e5;
}
.submit-section .submit-btn {
    padding: 12px 30px;
    font-weight: 600;
    font-size: 16px;
    min-width: 120px;
}
.submit-section .submit-btn + .submit-btn {
    margin-left: 15px;
}

/*-----------------
	29. Mentee Dashboard
-----------------------*/

.profile-sidebar {
    background-color: #fff;
    border: 1px solid #e3e8eb;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 1.5rem;
}
.pro-widget-content {
    border-bottom: 1px solid #f0f0f0;
    padding: 20px;
    text-align: center;
}
.user-widget {
    text-align: center;
}
.profile-info-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    text-align: left;
}
.profile-info-widget .booking-user-img {
    margin-right: 15px;
}
.profile-info-widget .booking-user-img img {
    border-radius: 4px;
    height: 90px;
    width: 90px;
    object-fit: cover;
}
.profile-det-info {
	overflow: hidden;
}
.profile-det-info h3 {
	font-size: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.mentee-details h5 {
    color: #757575;
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mentee-details h5 i {
	width: 18px;
}
.unread-msg {
    background-color: #1e88e5;
    border-radius: 2px;
    color: #26292c;
    font-size: 10px;
    font-style: normal;
    padding: 0 5px;
    position: absolute;
    right: 20px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

/*-----------------
	30. Profile Settings
-----------------------*/

.change-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.change-avatar .profile-img {
	margin-right: 15px;
}
.change-avatar .profile-img img {
    border-radius: 4px;
    height: 100px;
    width: 100px;
    object-fit: cover;
}
.change-avatar .change-photo-btn {
    margin: 0 0 10px;
    width: 150px;
}
.widget-profile.user-widget-profile .profile-info-widget .booking-user-img {
	padding: 0;
}
.widget-profile.user-widget-profile .profile-info-widget .booking-user-img img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

/*-----------------
	31. Appoitment List
-----------------------*/

.widget-profile {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}
.widget-profile .profile-info-widget {
	display: block;
	text-align: center;
}
.widget-profile .profile-info-widget .booking-user-img {
    display: inline-block;
    margin: 0 0 15px;
    width: auto;
    padding: 8px;
    background-color: #f7f7f7;
    border-radius: 50%;
}
.widget-profile .profile-info-widget .booking-user-img img {
    border-radius: 50%;
    height: 120px;
    width: 120px;
}
.appointment-list .profile-info-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    margin-right: auto;
    text-align: left;
}
.appointment-list .profile-info-widget .booking-user-img img {
    border-radius: 4px;
    height: 120px;
    object-fit: cover;
    width: 120px;
}
.appointments .appointment-list {
	background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	margin-bottom: 20px;
    padding: 20px;
}
.appointments .appointment-list:last-child {
	margin-bottom: 30px;
}
.appointments .appointment-action {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.appointment-action a + a {
	margin-left: 5px;
}
.appointment-action a {
    font-size: 13px;
}
.info-details {
    list-style: none;
    margin: 0;
    padding: 0;
}
.info-details li .title {
    color: #26292c;
    font-weight: 500;
}
.info-details li .text {
    color: #757575;
    display: block;
    font-size: 16px;
    overflow: hidden;
}
.info-details li {
	margin-bottom: 10px;
}
.info-details li:last-child {
	margin-bottom: 0;
}

/*-----------------
	32. Reviews
-----------------------*/

.doc-review.review-listing {
	margin: 0;
}
.review-listing.doc-review > ul > li {
	background-color: #fff;
	border: 1px solid #f0f0f0;
	padding: 20px;
}

/*-----------------
	33. Voice call
-----------------------*/

.modal-open .main-wrapper {
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
}
.call-main-row {
	bottom: 0;
	left: 0;
	overflow: auto;
	padding-bottom: inherit;
	padding-top: inherit;
	position: absolute;
	right: 0;
	top: 0;
}
.call-main-wrapper {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
}
.call-view {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
	width: 75%;
}
.call-window {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
	background-color: #fff;
	border: 1px solid #f0f0f0;
}
.fixed-header {
	background-color: #fff;
	border-bottom: 1px solid #f0f0f0;
	padding: 10px 15px;
}
.fixed-header .navbar {
	border: 0 none;
	margin: 0;
	min-height: auto;
	padding: 0;
}
.fixed-header .user-info a {
    color: #26292c;
    font-weight: 5000.;
}
.typing-text {
	color: #1e88e5;
	font-size: 12px;
	text-transform: lowercase;
}
.last-seen {
	color: #888;
	display: block;
	font-size: 12px;
}
.custom-menu {
	margin-top: 6px;
}
.fixed-header .custom-menu {
	margin: 0 0 1px;
}
.custom-menu.nav > li > a {
    color: #bbb;
    font-size: 26px;
    line-height: 32px;
    margin-left: 15px;
    padding: 0;
}
.custom-menu.navbar-nav > li > a:hover,
.custom-menu.navbar-nav > li > a:focus {
	background-color: transparent;
}
.custom-menu .dropdown-menu {
	left: auto;
	right: 0;
}
.call-contents {
	display: table-row;
	height: 100%;
}
.call-content-wrap {
	height: 100%;
	position: relative;
	width: 100%;
}
.voice-call-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	-ms-flex: 2;
	flex: 2;
}
.voice-call-avatar .call-avatar {
	margin: 15px;
	width: 150px;
	height: 150px;
	border-radius: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 3px;
	background-color: #fff;
}
.call-duration {
	display: inline-block;
	font-size: 30px;
	margin-top: 4px;
	position: absolute;
	left: 0;
}
.voice-call-avatar .call-timing-count {
	padding: 5px;
}
.voice-call-avatar .username {
	font-size: 20px;
	font-weight: 500;
}
.call-footer {
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	padding: 15px;
}
.call-icons {
	text-align: center;
	position: relative;
}
.call-icons .call-items {
	border-radius: 5px;
	padding: 0;
	margin: 0;
	list-style: none;
	display: inline-flex;
}
.call-icons .call-items .call-item {
	display: inline-block;
	text-align: center;
	margin-right: 15px;
}
.call-icons .call-items .call-item:last-child {
	margin-right: 0;
}
.call-icons .call-items .call-item a {
	color: #777;
    border: 1px solid #ddd;
    width: 50px;
    height: 50px;
    align-items: center;
    border-radius: 50px;
    display: inline-flex;
    font-size: 20px;
    justify-content: center;
	transform: scale(1);
	transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}
.call-icons .call-items .call-item a:hover {
	transform: scale(1.25);
}
.user-video {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
}
.user-video img {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	margin: 0 auto;
}
.user-video video {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	margin: 0 auto;
}
.my-video {
	position: absolute;
	z-index: 99;
	bottom: 20px;
	right: 20px;
}
.my-video ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.my-video ul li {
	float: left;
	width: 120px;
	margin-right: 10px;
}
.my-video ul li img {
	border: 3px solid #fff;
	border-radius: 6px;
}
.end-call {
	position: absolute;
	top: 7px;
	right: 0;
}
.end-call a {
    background-color: #f06060;
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    line-height: 10px;
    padding: 8px 25px;
    text-transform: uppercase;
}
.call-users {
	position: absolute;
	z-index: 99;
	bottom: 20px;
	right: 20px;
}
.call-users ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.call-users ul li {
	float: left;
	width: 80px;
	margin-left: 10px;
}
.call-users ul li img {
	border-radius: 6px;
	padding: 2px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.call-mute {
	width: 80px;
	height: 80px;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	text-align: center;
	line-height: 80px;
	border-radius: 6px;
	font-size: 30px;
	color: #fff;
	display: none;
	top: 0;
	border: 3px solid transparent;
}
.call-users ul li a:hover .call-mute {
	display: block;
}
.call-details {
	margin: 10px 0 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.call-info {
	margin-left: 10px;
	width: 100%;
}
.call-user-details,
.call-timing {
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.call-description {
	white-space: nowrap;
	vertical-align: bottom;
}
.call-timing {
	color: #727272;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 14px;
	margin-top: 1px;
	overflow: hidden;
	white-space: pre;
}

/*-----------------
	34. Video Call
-----------------------*/

.content-full {
	height: 100%;
	position: relative;
	width: 100%;
}
.video-window .fixed-header {
	padding: 0;
	border: 0;
}
.video-window .fixed-header .nav > li > a {
	padding: 18px 15px;
}

/*-----------------
	35. Outgoing Call
-----------------------*/

.call-box .call-wrapper {
	height: auto;
	text-align: center;
}
.call-box .call-wrapper .call-avatar {
	margin-bottom: 30px;
	cursor: pointer;
	animation: ripple 2s infinite;
}
.call-box .call-wrapper .call-user {
	margin-bottom: 30px;
}
.call-box .call-wrapper .call-user span {
	display: block;
	font-weight: 500;
	text-align: center;
}
.call-box .call-wrapper .call-items {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.call-box .call-wrapper .call-items .call-item {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid transparent;
    border-radius: 100%;
    color: #fff;
    line-height: 0;
    margin: 0 5px;
    padding: 15px;
}
.call-box .call-wrapper .call-items .call-item:hover {
	opacity: 0.9;
}
.call-box .call-wrapper .call-items .call-item:first-child {
	margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item:last-child {
	margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item.call-end {
	padding: 20px;
	margin: 30px 20px 0;
	background: #f06060;
	border: 1px solid #f06060;
	color: #fff;
	line-height: 0;
	border-radius: 100%;
}
.call-box .call-wrapper .call-items .call-item.call-start {
	padding: 20px;
	margin: 30px 20px 0;
	background: #55ce63;
	border: 1px solid #55ce63;
	color: #fff;
	line-height: 0;
	border-radius: 100%;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
	margin: 0 10px;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
	margin: 0 10px;
}
.call-box .call-avatar {
    border-radius: 100%;
    height: 140px;
    max-width: 140px;
    min-width: 140px;
    position: relative;
    width: 100%;
    border: 10px solid #fafafa;
}
.call-box .btn {
    background: rgba(0, 0, 0, 0);
    transition: all 0.3s ease 0s;
}
@-webkit-keyframes ripple {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}
	100% {
		-webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
	}
}
@keyframes ripple {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}
	100% {
		-moz-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
		box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
	}
}

/*-----------------
	36. Incoming Call
-----------------------*/

.incoming-btns {
	margin-top: 20px;
}
.call-wrapper {
    position: relative;
    height: calc(100vh - 130px);
}
.call-page .footer {
	display: none;
}
.dropdown-action .dropdown-toggle::after {
	display: none;
}
.call-modal .modal-body {
	padding: 40px;
}
.call-modal .modal-content {
    border: 0;
    border-radius: 10px;
}
.call-box .call-wrapper .call-user h4 {
	font-size: 24px;
}

/*-----------------
	38. Blog
-----------------------*/

.blog {
	border: 1px solid #e3e8eb;
	background-color: #fff;
	border-radius: 4px;
	margin-bottom: 30px;
	padding: 1.5rem;
	position: relative;
}
.section-blogs .blog {
	border: 1px solid #f0f0f0;
	box-shadow: 0 0 13px rgba(0,0,0,0.10);
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}
.blog-image {
	overflow: hidden;
}
.blog-image,
.blog-image > a,
.blog-image img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}
.blog-image img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.blog-image a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.blog-image {
	margin-bottom: 30px;
}
.blog-content {
	position: relative;
}
.blog-title {
	font-size: 22px;
	margin: 0 0 10px;
}
.blog-title a {
	color: #26292c;
}
.blog-title a:hover {
	color: #1e88e5;
}
.blog-content p {
	margin: 0 0 20px;
}
.read-more {
    display: inline-block;
    font-size: 16px;
    padding: 0;
    color: #1e88e5;
    text-decoration: underline;
    font-weight: bold;
}
.blog-info {
    color: #26292c;
    font-size: 14px;
    margin-bottom: 15px;
}
.blog-info a {
	color: #26292c;
}
.blog-info a:hover {
	color: #26292c;
}
.blog-info i {
	color: #1e88e5;
	font-size: 14px;
	margin-right: 5px;
}
.entry-meta {
	color: #26292c;
	font-size: 14px;
	list-style: none;
	margin-bottom: 15px;
	padding: 0;
}
.entry-meta li {
    display: inline-block;
    margin-right: 15px;
}
.entry-meta li:last-child {
	margin-right: 0;
}
.blog-pagination .pagination {
	margin-bottom: 30px;
}
.blog-pagination .pagination li a {
    padding: 12px 20px;
    font-weight: 600;
    color: #26292c;
    margin-right: 10px;
    border-radius: inherit !important;
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.disabled a {
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.active a {
	background: #1e88e5;
	border-color: transparent;
	color: #ffffff;
}
.post-left ul {
	margin: 0;
	padding-left: 0;
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.post-left ul li {
	margin-bottom: 5px;
	margin-right: 20px;
}
.post-left ul li:last-child {
	margin-right: 0;
}
.post-author a {
	display: flex;
	align-items: center;
}
.video {
	position: relative;
	padding-bottom: 56%;
	height: 0;
	overflow: hidden;
}
.video iframe,
.video object,
.video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}
.latest-posts {
	margin: 0;
	padding: 0;
}
.latest-posts li {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}
.latest-posts li:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: none;
}
.post-thumb {
	border-radius: 4px;
	width: 80px;
	float: left;
	overflow: hidden;
}
.post-thumb a img {
	border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.post-thumb a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.post-info {
	margin-left: 95px;
}
.post-info h4 {
	font-size: 15px;
	margin: 0 0 5px;
}
.post-info h4 a {
	color: #26292c;
}
.post-info h4 a:hover {
	color: #1e88e5;
}
.post-info p {
	color: #757575;
	font-size: 12px;
	margin: 0;
}
.post-info p i {
	color: #1e88e5;
	font-size: 14px;
	margin-right: 4px;
}
.category-widget .categories {
	list-style: none;
	margin: 0;
	padding: 0;
}
.category-widget .categories li {
    margin-bottom: 20px;
}
.category-widget .categories li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.category-widget .categories li a {
    color: #26292c;
    font-size: 15px;
    font-weight: 500;
}
.category-widget .categories li a span {
    float: right;
    color: #757575;
}
.category-widget .categories li a:hover {
	color: #1e88e5;
}
.category-widget .categories > li > a > i {
	color: #1e88e5;
	font-size: 18px;
	margin-right: 10px;
}
.tags-widget .card-body {
	padding-bottom: 1rem;
}
.tags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}
.tags li {
	float: left;
}
.tag {
	background-color: #fff;
	border-radius: 4px;
	color: #26292c;
	padding: 5px 10px;
	position: relative;
	margin: 0 .5rem .5rem 0;
	font-size: 14px;
	border: 1px solid #ccc;
	display: inline-block;
}
.tag:hover {
	background-color: #1e88e5;
	border-color: #1e88e5;
	color: #fff;
}
.comment-by {
	display: block;
	font-size: 14px;
	line-height: 21px;
	margin: 0 0 10px;
}
.comments-list .comment-block p {
    font-size: 14px;
    margin-bottom: 5px;
}
.blog-col{
	width: 100%;
}
.custom-edit-service h4{
	padding-bottom: 15px;
	margin-bottom: 0;
}
.custom-edit-service label{
	font-size: 16px;
}
.custom-edit-service textarea.form-control{
	min-height: 200px;
}
.custom-edit-service .service-upload{
	border: 1px solid #dcdcdc;
    border-radius: .25rem;
    text-align: center;
    padding: 70px 0;
    margin-bottom: 30px;
    background-color: #fff;
    position: relative;
}
.custom-edit-service .service-upload i{
	font-size: 50px;
    color: #858585;
}
.custom-edit-service .service-upload span{
	font-size: 15px;
    color: #858585;
    margin-top: 14px;
    display: block;
}
.custom-edit-service .service-upload input[type="file"]{
	position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.custom-edit-service .upload-wrap{
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}
.custom-edit-service .upload-wrap li{
	margin: 10px;
}
.custom-edit-service .upload-wrap li .upload-images{
	width: 100px;
}
.custom-edit-service .upload-wrap li img{
	max-width: 100%;
	height: auto;
}

/*-----------------
	39. Blog Details
-----------------------*/

.blog-view .blog-title {
	font-size: 24px;
}
.blog-content p:last-child {
	margin-bottom: 0;
}
.blog-view .blog-info {
	border: 0;
	margin-bottom: 20px;
	padding: 0;
}
.social-share {
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
}
.social-share > li {
	/* display: inline-block; */
	float: left;
	margin-left: 10px;
	text-align: center;
}
.social-share > li:first-child {
	margin-left: 0;
}
.social-share > li > a {
	border: 1px solid #dfdfdf;
	color: #666;
	display: inline-block;
	font-size: 22px;
	height: 40px;
	line-height: 40px;
	width: 40px;
}
.social-share > li > a:hover {
	background-color: #1e88e5;
	color: #fff;
	border-color: #1e88e5;
}
.blog-reply > a {
	color: #999;
	font-size: 12px;
	font-weight: 500;
}
.blog-date {
	color: #999;
	font-size: 12px;
}
.blog-comments .comments-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.blog-comments .comments-list li {
	clear: both;
	padding-left: 80px;
}
.blog-comments .comments-list li .comment {
	margin-bottom: 20px;
}
.blog-comments .comments-list li .comment-author {
	left: 0;
	position: absolute;
}
.blog-comments .comments-list li img.avatar {
	height: 58px;
	width: 58px;
	border-radius: 58px;
}
.blog-comments .comment-btn {
    color: #1e88e5;
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
}
.blog-author-name {
    color: #26292c;
    font-size: 16px;
    font-weight: 600;
}
.new-comment label {
	font-weight: 500;
}
.comment-submit .btn {
	background-color: #1e88e5;
	border-color: #1e88e5;
	border-radius: 0;
	font-size: 18px;
	padding: 8px 26px;
	color: #fff;
}
.about-author-img {
	background-color: #fff;
	height: 100px;
	overflow: hidden;
	position: absolute;
	width: 100px;
}
.author-details {
	margin-left: 120px;
}
.about-author {
	min-height: 100px;
}
.author-details .blog-author-name {
	display: inline-block;
	margin-bottom: 10px;
}
.post-author img {
    border-radius: 100%;
    width: 28px;
    margin-right: 5px;
}
.blog-details .course-title{
	font-size: 28px;
	width: 100%;
	padding-bottom: 20px;
}
.blog-details .blog-details-img{
	width: 100%;
	padding-bottom: 30px;
}
.blog-details .date-col{
	padding-bottom: 20px;
}
.blog-details .date-col > span{
	padding-right: 25px;
}
.blog-details .date-col > span i{
	color: #1e88e5;
	margin-right: 5px;
}
.blog-details blockquote {
    position: relative;
    text-align: left;
    padding: 1.2em 0 2em 38px;
    border: none;
    margin: 20px auto 20px;
    max-width: 800px;
    width:100%;
    display: block;
}
.blog-details blockquote:after {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    left: 0;
    color: #1e88e5;
    top: 0;
    background: -moz-linear-gradient(top,#1e88e5 0%,#1e88e5 60%,rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top,#1e88e5 0%,#1e88e5 60%,rgba(255,255,255,0) 100%);
    background: linear-gradient(top,#1e88e5 0%,#1e88e5 60%,rgba(255,255,255,0) 100%);
}
.blog-details blockquote:before {
    content:"\f10d";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 20px;
    display: block;
    margin-bottom: 0.8em;
    color: #1e88e5;
}
.blog-details blockquote > cite, 
.blog-details blockquote > p > cite {
    display: block;
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 700;
    font-style: normal;
    margin-top: 1.1em;
    letter-spacing: 0;
    font-style:italic;
}
.share-post {
	list-style: none;
	padding: 10px 0 0;
	margin: 0;
}
.share-post li {
	display: inline-block;
	margin: 5px 10px 5px 0;
}
.share-post li a {
	border: 1px solid #dfdfdf;
	width: 40px;
	height: 40px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}
.share-post li a:hover {
	background-color: #1e88e5;
	color: #fff;
	border-color: #1e88e5; 
}
.about-author {
	width: 100%;
}
.about-author .left {
	float: left;
	width: 140px;
	padding-right: 20px;
}
.about-author .right {
	float: left;
	width: 86%;
}
.about-author .right p {
	margin-bottom: 0;
}
.product-thumbnail {
	position: relative;
    margin-bottom: 0;
    margin-top: 10px;
}
.product-remove {
	position: absolute;
    top: -10px;
    right: -6px;
    color: #f06060;
    cursor: pointer;
}
.blog-view .blog-title {
	font-size: 24px;
}
.blog-content p:last-child {
	margin-bottom: 0;
}
.blog-view .blog-info {
	border: 0;
	margin-bottom: 20px;
	padding: 0;
}
.social-share {
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
}
.social-share > li {
	/* display: inline-block; */
	float: left;
	margin-left: 10px;
	text-align: center;
}
.social-share > li:first-child {
	margin-left: 0;
}
.social-share > li > a {
	border: 1px solid #dfdfdf;
	color: #666;
	display: inline-block;
	font-size: 22px;
	height: 40px;
	line-height: 40px;
	width: 40px;
}
.social-share > li > a:hover {
	background-color: #1e88e5;
	color: #fff;
	border-color: #1e88e5;
}
.blog-reply > a {
	color: #999;
	font-size: 12px;
	font-weight: 500;
}
.blog-date {
	color: #999;
	font-size: 12px;
}
.blog-comments .comments-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.blog-comments .comments-list li {
	clear: both;
	padding-left: 80px;
}
.blog-comments .comments-list li .comment {
	margin-bottom: 20px;
}
.blog-comments .comments-list li .comment-author {
	left: 0;
	position: absolute;
}
.blog-comments .comments-list li img.avatar {
	height: 58px;
	width: 58px;
	border-radius: 58px;
}
.blog-comments .comment-btn {
    color: #1e88e5;
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
}
.blog-author-name {
    color: #26292c;
    font-size: 16px;
    font-weight: 600;
}
.new-comment label {
	font-weight: 500;
}
.comment-submit .btn {
	background-color: #1e88e5;
	border-color: #1e88e5;
	border-radius: 0;
	font-size: 18px;
	padding: 8px 26px;
	color: #fff;
}
.about-author-img {
	background-color: #fff;
	height: 100px;
	overflow: hidden;
	position: absolute;
	width: 100px;
}
.author-details {
	margin-left: 120px;
}
.about-author {
	min-height: 100px;
}
.author-details .blog-author-name {
	display: inline-block;
	margin-bottom: 10px;
}
.post-author img {
    border-radius: 100%;
    width: 28px;
    margin-right: 5px;
}

/*-----------------
	40. Blog Grid
-----------------------*/

.grid-blog .blog-title {
    font-size: 18px;
    margin: 0 0 5px;
    min-height: 43px;
}
.grid-blog .blog-content p {
	margin: 0 0 15px;
	color: #26292c;
}
.grid-blog .blog-info {
	margin-bottom: 15px;
}
.grid-blog .blog-image {
	margin-bottom: 20px;
	border-radius: 4px 4px 0 0;
}
.blog.grid-blog {
	padding: 0;
}
.blog.grid-blog .blog-content {
	padding: 0 20px 20px;
}
.grid-blog .entry-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0;
}
.grid-blog .entry-meta li {
	margin-bottom: 10px;
}
.grid-blog .entry-meta li:last-child {
	flex: 0 0 100px;
	max-width: 100px;
}
.grid-blog .post-author {
    width: 189px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.grid-blog .post-author a:hover {
	color: #1e88e5
}

/*-----------------
	41. Map List & Grid
-----------------------*/

.map-page .footer {
	display: none;
}
.map-page .header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1030;
}
.map-page .content {
	padding-top: 100px;
}
.map-right {
	bottom: 0;
    height: 100%;
    min-height: 100%;
	padding-right: 30px;
    position: fixed;
    right: 0;
    top: 100px;
    z-index: 1029;
}
.map-listing {
    height: calc(100vh - 130px);
    width: 100%;
}
.map-listing .profile-widget {
    width: 200px !important;
    padding: 0;
    border: 0;
	margin-bottom: 0;
}
.map-right .available-info {
	margin-bottom: 0;
}
.view-icons {
    display: inline-flex;
    float: right;
}
.view-icons a {
	align-items: center;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	color: #212529;
	display: flex;
	font-size: 20px;
	justify-content: center;
	padding: 4px 10px;
	text-align: center;
	margin-left: 10px;
	width: 44px;
	height: 44px;
}
.view-icons a:hover {
	border-color: #1e88e5;
	color: #1e88e5;
}
.view-icons a.active {
    background-color: #1e88e5;
	border-color: #1e88e5;
	color: #fff;
}

/*-----------------
	42. Loader
-----------------------*/

#loader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 999999;
}
.loader {
	display: inline-block;
	height: 50px;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	width: 50px;
	position: absolute;
	top: 50%;
}
.loader span {
	-webkit-animation: loader 1.3s linear infinite;
	animation: loader 1.3s linear infinite;
	background-color: #1e88e5;
	border-radius: 100%;
	display: inline-block;
	height: 50px;
	position: absolute;
	width: 50px;
}
.loader span:last-child {
	animation-delay: -0.8s;
	-webkit-animation-delay: -0.8s;
}
@keyframes loader {
	0% {
		transform: scale(0, 0);
		opacity: 0.5;
	}
	100% {
		transform: scale(1, 1);
		opacity: 0;
	}
}
@-webkit-keyframes loader {
	0% {
		-webkit-transform: scale(0, 0);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1, 1);
		opacity: 0;
	}
}


/* How it works */

.how-it-works {
	background-image: url(../../assets/img/how-it-works-bg.jpg);
	padding: 60px 0;
	position: relative;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: auto;
}
.feature-box {
    border: 1px solid #ececec;
	border-radius: 6px;
	background-color: #fff;
    padding: 30px;
    position: relative;
	z-index: 9;
	box-shadow: 0 0 13px rgba(0,0,0,0.10);
	width: 100%;
}
.feature-header {
    margin-bottom: 10px;
}
.feature-icon {
    margin-right: 15px;
    font-size: 30px;
    width: 105px;
    border-radius: 100px;
    height: 105px;
    display: flex;
    display: -webkit-flex;
    display: -ms-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    color: #fff;
    margin: 0 auto;
    position: relative;
    border: 1px solid #bfbfbf;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.feature-icon i{
	position: relative;
}
.feature-icon span.circle{
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #1e88e5;
	border: 8px solid #fff;
	border-radius: 100px;
}
.feature-icon span.circle:before{
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 100px;
}
.feature-box h4 {
    font-size: 18px;
    margin: 0 0 15px;
}
.feature-heading {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
}
.feature-text {
	font-size: 20px;
	font-weight: bold;
	padding-top: 20px;
}
.feature-box p {
	line-height: 24px;
	color: #26292c;
	font-weight: 300;
}
.feature-box:hover .feature-icon span.circle {
	background-color: #1879cd;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* Popular Courses */

.path-section {
    padding: 60px 0;
    background-color: #f2f2f2;
}
.path-section .section-header.text-center .sub-title {
	max-width: 825px;
}
.learning-path-col .image-col-merge {
	position: relative;
	overflow: hidden;
	border-radius: 4px;
}
.learning-path-col .image-col-merge img {
	width: 100%;
	height: auto;
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.learning-path-col .image-col-merge:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.path-section .large-col-image, .path-section .small-col-image{
	position: relative;
	border-radius: 4px;
}
.path-section .image-col-merge:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-color: rgba(0,0,0,0.46);
	z-index: 9;
}
.path-section .large-col {
    width: 100%;
    margin-bottom: 30px;
}
.path-section .text-col {
	position: absolute;
	left :50%;
	top: 50%;
	-webkit-transform: translate(-50% , -50%);
	-ms-transform: translate(-50% , -50%);
	transform: translate(-50% , -50%);
	width: 100%;
	text-align: center;
	padding: 0px 20px;
	z-index: 10;
}
.path-section .text-col h5 {
	font-size: 20px;
	color: #f2f2f2;
	margin-bottom: 0;
}

/* Statistics */

.statistics-section {
	position: relative;
	padding: 60px 0;
	background-image: url(../../assets/img/statistics-bg.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.statistics-section:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.56);
}
.statistics-list span {
	color: #ffffff;
	font-size: 38px;
	font-weight: 500;
}
.statistics-list h3 {
    font-size: 24px;
    color: #1e88e5;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 7px;
    margin-bottom: 0;
}
.author-country {
    color: #767676;
}
.product .rating i {
	font-size: 13px;
}
.product {
    background-color:#ffffff;
    border:1px solid #eaedf2;
    border-radius:4px;
    margin-bottom:30px;
    position:relative;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
	box-shadow: 0 0 13px rgba(0,0,0,0.10);
}
.product-img {
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-radius: 4px 4px 0 0;
}
.product-img img {
    width: 100%;
    border-radius: 4px 4px 0 0;
	transform: translateZ(0);
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.product-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.product-content {
	padding: 20px;
	display: inline-block;
	width: 100%;
}
.author-name a {
    color: #656565;
    display: inline-block;
}
.author-name img {
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    width: 30px;
	margin-right: 3px;
}
.product .rating {
    margin-left: auto;
    margin-bottom: 15px;
    width: auto;
}
.rating{
    margin-bottom: 10px;
}
.rating i{
	color: #dedfe0;
    font-size: 16px;
}
.rating i.filled {
    color: #febe42;
}
.rating .rating-number{
    color: #29303b;
    margin-bottom: 0;
    font-size: 12px;
}
.rating-number span{
    font-weight: 600;
    color: #a1a7b3;
    margin-left: 5px;
}
.price {
    color: #26292c;
    font-size: 30px;
    font-weight: bolder;
	margin: 0;
}
.price small {
    color: #767676;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    line-height: inherit;
    text-transform: lowercase;
}
.author-info {
    margin-bottom: 15px;
}
.product .product-content .title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 500;
	word-wrap: break-word;
	overflow: hidden;
}
.popular-courses {
	padding: 0px 0 60px;
}
.popular-courses .course-box {
	-ms-flex: 0 0 100%;
    flex: 100%;
    max-width: 100%;
}
.popular-courses .owl-nav {
	display: none;
}
.popular-courses .owl-theme .owl-dots .owl-dot span {
	width: 20px;
	height: 5px;
	margin: 7px 4px;
	border-radius: 0;
	transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
.popular-courses .owl-theme .owl-dots .owl-dot.active span {
	width: 30px;
	background: #1e88e5;
	transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
.header-fixed {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
	z-index: 999;
}
.header {
	height: 70px;
	background-color: #fff;
}
.btn {
    letter-spacing: 0.02em;
}
.pro-avatar {
	color: #1e88e5;
	background-color: rgba(30, 136, 229, 0.2);
	border: 3px solid #1e88e5;
	font-size: 30px;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 15px;
}
.form-control-label {
    color: #262626;
}

/*-----------------
	53. OTP
-----------------------*/

.account-box {
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	background-color: #ffffff;
    border-radius: 10px;
    margin: 0px auto 0;
    padding: 30px;
    max-width: 500px;
    margin: 0 auto;
    position: relative;
    margin: 0px auto;
    border: 1px solid #eaedf2;
    box-shadow: 0 0 2px rgba(0,0,0,0.05);
	width: 500px;
}
.login-right {
    width: 100%;
}
.toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #757575;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.toggle-password:hover {
    background-color: #f3f3f3;
}
.pass-group {
	position: relative;
}

/*-----------------
	43. Dashboard
-----------------------*/

.dash-board-list .icon-col {
    font-size: 22px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dash-board-list.blue .dash-widget {
	background-color: rgba(0,84,254,0.07);
}
.dash-board-list.yellow .dash-widget {
	background-color: rgba(249,206,35,0.1);
}
.dash-board-list.pink .dash-widget {
	background-color: #FD51811A;
}
.dash-board-list.blue .icon-col {
	background-color: rgba(0,84,254,0.07);
}
.dash-board-list.yellow .icon-col {
	background-color: rgba(249,206,35,0.1);
}
.dash-board-list.pink .icon-col {
	background-color: #FD51811A;
} 
.pending {
	background-color: #FBA20A;
	border-radius:4px;
	color: #fff;
	text-transform: uppercase;
	padding: 3px 10px;
}
.accept {
 	background-color: #04AE1B;
	border-radius:4px;
	color: #fff;
	text-transform: uppercase;
	padding: 3px 10px;
}
.reject {
	background-color: #FF0000;
	border-radius:4px;
	color: #fff;
	text-transform: uppercase;
	padding: 3px 10px;
}
div.dataTables_wrapper div.dataTables_length label, 
div.dataTables_wrapper div.dataTables_filter label{
	font-weight: normal;
    text-align: left;
    white-space: nowrap;
    display: inline-block;
    margin-bottom: 1rem;
}
div.dataTables_wrapper div.dataTables_filter{
	text-align: right;
}
div.dataTables_wrapper div.dataTables_filter input{
}
div.dataTables_wrapper div.dataTables_filter input{
	margin-left: 0.5em;
    display: inline-block;
    width: auto;
}
.call-icons .call-items .call-item a.call-end {
	background-color: #f06060;
	border-color: #f06060;
	color: #fff;
}
.call-view {
    width: 100%;
    height: 100%;
}
.user-name {
    color: #fff;
    font-size: 20px;
    margin-top: 10px;
    text-align: center;
}

/*-----------------
	44. Responsive
-----------------------*/

@media only screen and (min-width:768px) {
	.avatar-xxl {
		width: 8rem;
		height: 8rem;
	}
	.avatar-xxl .border {
		border-width: 4px !important;
	}
	.avatar-xxl .rounded {
		border-radius: 12px !important;
	}
	.avatar-xxl .avatar-title {
		font-size: 42px;
	}
	.avatar-xxl.avatar-away:before,
	.avatar-xxl.avatar-offline:before,
	.avatar-xxl.avatar-online:before {
		border-width: 4px;
	}
}
@media (min-width: 992px) {
	.main-nav > li {
		margin-right: 30px;
	}
	.main-nav > li:last-child {
		margin-right: 0;
	}
	.main-nav li {
		display: block;
		position: relative;
	}
	.main-nav > li > a {
		line-height: 70px;
		padding: 0 !important;
	}
	.main-nav > li > a > i {
		font-size: 12px;
		margin-left: 3px;
	}
	.main-nav li > ul {
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 3px  rgba(0, 0, 0, 0.1);
		display: block;
		font-size: 14px;
		left: 0;
		margin: 0;
		min-width: 200px;
		opacity: 0;
		padding: 0;
		position: absolute;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
		top: 100%;
		visibility: hidden;
		z-index: 1000;
	}
	.main-nav li .submenu::before {
		border: 7px solid #fff;
		border-color: transparent transparent #fff #fff;
		box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
		content: "";
		left: 45px;
		position: absolute;
		top: 2px;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
	}
	.main-nav li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.main-nav .has-submenu.active > a {
		color: #1e88e5;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #1e88e5;
	}
	.main-nav > li .submenu li:first-child a {
		border-top: 0;
	}
	.main-nav > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: -1px;
		margin-right: 0;
	}
	.main-nav > li .submenu > li .submenu {
		left: 100%;
		top: 0;
		margin-top: 10px;
	}
	.main-nav li .submenu a:hover {
		color: #1e88e5;
		letter-spacing: 0.5px;
		padding-left: 20px;
	}
	.main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
		top: 20px;
		margin-left: -35px;
		box-shadow: 1px 1px 0 0 rgba(0,0,0,.15);
		border-color: transparent #fff #fff transparent;
	}
	.header-navbar-rht li.show > .dropdown-menu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.header-navbar-rht li .dropdown-menu {
		border-radius: 5px;
		padding: 0;
		margin: 0;
		min-width: 200px;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		display: block;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
	}
	.profile-custom-list > li{
		width: 33.33%;
	}
	.custom-about p{
		width: 73%;
	}
}
@media (min-width: 1200px) {
	.path-section .text-col h5{
		font-size: 24px;
	}
	.container {
		max-width: 1200px;
	}
}

@media only screen and (max-width: 1399px) {
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 180px;
	}
	.row.row-grid {
		margin-left: -8px;
		margin-right: -8px;
	}
	.row.row-grid > div {
		padding-right: 8px;
		padding-left: 8px;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
}
@media only screen and (max-width: 1199px) {
	.header-nav {
		padding-left: 20px;
		padding-right: 20px;
	}
	.container-fluid {
		padding-left: 20px;
		padding-right: 20px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 150px;
	}
	.chat-cont-left {
		-ms-flex: 0 0 40%;
		flex: 0 0 40%;
		max-width: 40%;
	}
	.chat-cont-right {
		-ms-flex: 0 0 60%;
		flex: 0 0 60%;
		max-width: 60%;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.dash-widget {
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
	.circle-bar {
		margin: 0 0 15px;
	}
	.header-navbar-rht li.contact-item {
		display: none;
	}
	.map-right {
		height: 450px;
		position: static;
		min-height: inherit;
		margin-bottom: 30px;
		padding-right: 15px;
	}
	.map-listing {
		height: 450px;
	}
	.map-page .content {
		padding-top: 100px;
	}
}
@media only screen and (max-width: 991.98px) {
	.main-nav {
		padding: 0;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}
	.main-nav ul {
		background-color: #3474ac;
		display: none;
		list-style: none;
		margin: 0;
		padding-left: 0;
	}
	.main-nav > li {
		border-bottom: 1px solid #1663a6;
		margin-left: 0;
	}
	.main-nav li + li {
		margin-left: 0;
	}
	.main-nav > li > a {
		line-height: 1.5;
		padding: 15px 20px !important;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	.main-nav > li.active > a,
	.main-nav .has-submenu.active .submenu li.active > a,
	.main-nav .has-submenu.active > a{
		color: #fff !important;
	}
	.main-nav > li > a > i {
		float: right;
		margin-top: 5px;
	}
	.main-nav > li .submenu li a {
		border-top: 0;
		color: #fff;
		padding: 10px 15px 10px 35px;
	}
	.main-nav > li .submenu ul li a {
		padding: 10px 15px 10px 45px;
	}
	.main-nav > li .submenu > li.has-submenu > a::after {
		content: "\f078";
	}
	.main-nav .has-submenu.active > a {
		color: #1879cd;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #1879cd;
	}
	.login-left {
		display: none;
	}
	.main-menu-wrapper {
		order: 3;
		width: 260px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1060;
		transform: translateX(-260px);
		transition: all 0.4s;
		background-color: #15558d;
	}
	.menu-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.navbar-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
	#mobile_btn {
		display: inline-block;
	}
	.section-search {
		min-height: 330px;
	}
	.footer-widget {
		margin-bottom: 30px;
	}
	.chat-cont-left, .chat-cont-right {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
		width: 100%;
	}
	.chat-cont-left {
		border-right: 0;
	}
	.chat-cont-right {
		position: absolute;
		right: -100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
	}
	.chat-cont-right .chat-header {
		justify-content: start;
		-webkit-justify-content: start;
		-ms-flex-pack: start;
	}
	.chat-cont-right .chat-header .back-user-list {
		display: block;
	}
	.chat-cont-right .chat-header .chat-options {
		margin-left: auto;
	}
	.chat-window.chat-slide .chat-cont-left {
		left: -100%;
		margin-left: -20px;
	}
	.chat-window.chat-slide .chat-cont-right {
		right: 0;
		opacity: 1;
		visibility: visible;
	}
	.day-slot li.left-arrow {
		left: -10px;
	}
	.container {
		max-width: 100%;
	}
	.appointments .appointment-action {
		margin-top: 10px;
	}
	.appointments .appointment-list {
		display: block;
	}
	.banner-wrapper {
		max-width: 720px;
	}
	.search-box .search-info {
		-ms-flex: 0 0 410px;
		flex: 0 0 410px;
		width: 410px;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 2.125rem;
	}
	.card-label > label {
		font-size: 12px;
	}
	.time-slot li .timing.selected::before {
		display: none;
	}
	.review-listing .recommend-btn {
		float: none;
	}
	.dash-widget {
		flex-direction: unset;
		text-align: left;
	}
	.circle-bar {
		margin: 0 15px 0 0;
	}
	.sidebar-overlay.opened {
		display: block;
	}
	.about-content {
		margin-bottom: 30px;
	}
	.statistics-list h3 {
		font-size: 22px;
	}
	.statistics-list span {
		font-size: 30px;
	}
	.user-info-left, .mentor-widget {
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
	.mentor-img {
		margin: 0 auto 20px;
	}
	.mentor-action {
		-webkit-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
	}
	.hire-rate {
		font-size: 22px;
	}
	.blue-btn-radius {
		font-size: 16px;
	}
	.custom-search-form {
		padding-top: 15px;
	}
	.banner-wrapper .banner-header p {
		font-size: 20px;
	}
	.search-box .form-group .form-control::-webkit-input-placeholder {
		font-size: 14px;
	}
	.search-box .form-group .form-control::-moz-placeholder {
		font-size: 14px;
	}
	.search-box .form-group .form-control:-ms-input-placeholder {
		font-size: 14px;
	}
	.search-box .form-group .form-control::-ms-input-placeholder {
		font-size: 14px;
	}
	.search-box .form-group .form-control::placeholder {
		font-size: 14px;
	}
	.section-header.text-center .sub-title{
		font-size: 16px;
	}
	.search-box .form-control{
		text-overflow: ellipsis;
  		white-space: nowrap;
  		overflow: hidden;
	}
	.feature-box {
		margin-bottom: 30px;
	}
	.feature-text {
		font-size: 18px;
	}
	.product .product-content .title {
		font-size: 16px;
	}
}
@media only screen and (max-width: 849.98px) {
	.row.row-grid > div {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media only screen and (max-width: 767.98px) {
	body {
		font-size: 0.875rem;
	}
	h1, .h1 {
		font-size: 2rem;
	}
	h2, .h2 {
		font-size: 1.75rem;
	}
	h3, .h3 {
		font-size: 1.375rem;
	}
	h4, .h4 {
		font-size: 1rem;
	}
	h5, .h5 {
		font-size: 0.875rem;
	}
	h6, .h6 {
		font-size: 0.75rem;
	}
	.content {
		padding: 15px 0 0;
	}
	.account-page .content {
		padding: 15px 15px;
		min-height: inherit;
	}
	.account-box{
		padding: 10px;
		width: auto;
	}
	.container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}
	.profile-sidebar {
		margin-bottom: 20px;
	}
	.mentor-slider {
		margin-top: 25px;
	}
	.breadcrumb-bar {
		height: auto;
	}
	.login-right {
		margin: 0 15px;
		padding: 15px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 250px;
	}
	.chat-window .chat-cont-right .chat-header .media .media-body {
		display: none;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 2rem;
	}
	.banner-wrapper .banner-header p {
		font-size: 1rem;
	}
	.section-header h2 {
		font-size: 1.875rem;
	}
	.section-header .sub-title {
		font-size: 0.875rem;
	}
	.section-header p {
		font-size: 0.9375rem;
	}
	.footer-title {
		font-size: 1.125rem;
		margin-bottom: 20px;
	}
	.search-box {
		max-width: 535px;
		margin: 0 auto;
	}
	.search-box form {
		-ms-flex-direction: column;
		flex-direction: column;
		word-wrap: break-word;
		background-clip: border-box;
	}
	.search-box .form-group {
		margin-bottom: 15px;
	}
	.search-box .search-location {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		width: 100%;
	}
	.search-box .search-info {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		width: 100%;
	}
	.search-box .search-btn {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		min-height: 46px;
		width: 100%;
	}
	.search-box .search-btn span {
		display: inline-block;
		margin-left: 5px;
		text-transform: uppercase;
	}
	.day-slot li span {
		font-size: 16px;
		text-transform: unset;
	}
	.time-slot li .timing span {
		display: block;
	}
	.submit-section.proceed-btn {
		margin-bottom: 20px;
	}
	.day-slot li small.slot-year {
		display: none;
	}
	.success-cont h3 {
		font-size: 22px;
	}
	.view-inv-btn {
		font-size: 14px;
		padding: 10px 30px;
	}
	.invoice-info.invoice-info2 {
		text-align: left;
	}
	.invoice-item .invoice-details {
		text-align: left;
	}
	.about-content a {
		padding: 12px 20px;
	}
	.submit-section .submit-btn {
		padding: 10px 20px;
		font-size: 15px;
		min-width: 105px;
	}
	.booking-user-info .booking-user-img {
		width: 75px;
	}
	.booking-user-info .booking-user-img img {
		height: 75px;
		width: 75px;
	}
	.btn.btn-danger.trash {
		margin-bottom: 20px;
	}
	.nav-tabs.nav-tabs-bottom > li > a.active, .nav-tabs.nav-tabs-bottom > li > a.active:hover, .nav-tabs.nav-tabs-bottom > li > a.active:focus {
		background-color: #f5f5f5;
	}	
	.nav-tabs.nav-justified {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs.nav-justified > li > a.active,
	.nav-tabs.nav-justified > li > a.active:hover,
	.nav-tabs.nav-justified > li > a.active:focus {
		border-color: transparent transparent transparent #1e88e5;
		border-left-width: 2px;
	}
	.nav-tabs {
		border-bottom: 0;
		position: relative;
		background-color: #fff;
		padding: 5px 0;
		border: 1px solid #ddd;
		border-radius: 3px;
	}
	.nav-tabs .nav-item {
		margin-bottom: 0;
	}
	.nav-tabs > li > a {
		border-width: 2px;
		border-left-color: transparent;
	}
	.nav-tabs .nav-link {
		border-width: 2px;
	}
	.nav-tabs > li > a:hover,
	.nav-tabs > li > a:focus {
		background-color: #fafafa;
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active,
	.nav-tabs > li > a.active,
	.nav-tabs > li > a.active:hover,
	.nav-tabs > li > a.active:focus {
		border-color: transparent transparent transparent #1e88e5 !important;
		border-left-width: 2px;
	}
	.nav-tabs > li.open:not(.active) > a,
	.nav-tabs > li.open:not(.active) > a:hover,
	.nav-tabs > li.open:not(.active) > a:focus {
		background-color: #fafafa;
	}
	.nav-tabs.nav-tabs-solid {
		padding: 5px;
	}
	.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
		border-radius: 5px;
	}
	.nav-tabs-justified {
		border-bottom: 1px solid #ddd;
	}
	.nav-tabs-justified > li > a.active,
	.nav-tabs-justified > li > a.active:hover,
	.nav-tabs-justified > li > a.active:focus {
		border-width: 0 0 0 2px;
		border-left-color: #1e88e5;
	}
	.review-listing > ul li .comment .comment-body .meta-data span.comment-date {
		margin-bottom: 5px;
	}
	.review-listing > ul li .comment .comment-body .meta-data .review-count {
		position: unset;
	}
	.my-video ul li {
		width: 50px;
	}
	.call-users ul li {
		width: 50px;
	}
	.call-mute {
		font-size: 20px;
		height: 50px;
		line-height: 50px;
		width: 50px;
	}
	.call-duration {
		font-size: 24px;
	}
	.voice-call-avatar .call-avatar {
		height: 100px;
		width: 100px;
	}
	.user-tabs {
		margin-top: 1.5rem;
	}
	.user-tabs .nav-tabs > li > a {
		border-left: 2px solid transparent;
		border-bottom: 0;
		padding: .5rem 1rem;
	}
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active, 
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover, 
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
		border-left-width: 2px;
		color: #1e88e5;
	}
	.mentor-widget {
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
	.user-info-right {
		margin-left: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.user-info-left {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.mentor-img {
		margin: 0 auto 20px;
	}
	.mentor-action {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.breadcrumb-bar .breadcrumb-title {
		font-size: 18px;
	}
	.dash-widget h6 {
		font-size: 15px;
	}
	.dash-widget h3 {
		font-size: 20px;
	}
	.dash-widget p {
		font-size: 13px;
	}
	.mentor-widget .usr-name {
		font-size: 18px;
	}
	.exp-title, .booking-total ul li span, .booking-total ul li .total-cost {
		font-size: 14px;
	}
	.invoice-item .customer-text {
		font-size: 16px;
	}
	.call-wrapper {
		height: calc(100vh - 100px);
	}
	.grid-blog .blog-title {
		min-height: inherit;
	}
	.blog-title {
		font-size: 20px;
	}
	.blog-view .blog-title {
		font-size: 22px;
	}
	.full-width-mob {
		max-width: 100%;
	}
	.booking-summary ul li:first-child, .booking-summary ul li:last-child, .booking-summary ul li {
		width: 100% !important;
		padding: 3px 0 !important;
	}
	.blog-details .date-col > span {
		padding-right: 0;
		width: 100%;
		padding: 5px 0;
	}
	.about-author {
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
	}
	.about-author .left {
		width: auto;
		max-width: 140px;
		margin: 0 auto;
		padding-right: 0;
		padding-bottom: 30px;
	}
	.about-author .right {
		width: 100%;
	}
	.search-box .form-group .form-control {
		font-size: 14px;
	}
	.profile-custom-list > li:nth-child(2n+1){
		clear: left;
	}
	.bg-pattern-style-register {
		height: auto;
	}
	.footer .footer-widget .footer-logo img {
		max-height:40px;
	}
	.section-search, .section-mentor, .path-section, .section-blogs {
		padding: 30px 0;
	}
	.section-header span {
		font-size: 18px;
	}
	.popular-courses {
		padding: 0 0 30px;
	}
	.how-it-works {
		padding: 30px 0 0;
	}
	.footer .footer-bottom .copyright {
		padding: 0 0 20px;
	}
	.custom-sidebar-nav ul li a, .custom-edit-service label {
		font-size: 14px;
	}
	.pro-avatar {
		font-size: 24px;
		width: 80px;
		height: 80px;
	}
	.chat-window .chat-scroll .user-name, .pro-content .title {
		font-size: 15px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		font-size: 13px;
	}
	.chat-cont-left .chat-header span, .widget-title, .login-header h3 {
		font-size: 18px;
	}
	.chat-window .chat-scroll {
		max-height: calc(100vh - 290px);
	}
	.table > thead > tr > th {
		font-size: 13px;
	}
	.review-listing > ul li .comment .comment-body .meta-data span, .recommended, .review-listing > ul li .comment .comment-body .comment-reply .comment-btn {
		font-size: 14px;
	}
	.hire-rate {
		font-size: 20px;
	}
	.category-widget .categories li a {
		font-size: 14px;
	}
	.invoice-content {
		padding: 1.5rem;
	}
	.statistics-list {
		margin: 15px 0;
	}
	.statistics-section {
		padding: 15px 0;
	}
	.statistics-list h3 {
		font-size: 18px;
	}
	.statistics-list span {
		font-size: 26px;
	}
	.header-navbar-rht li a.header-login, .header-navbar-rht > li > a {
		font-size: 14px;
	}
}

@media only screen and (max-width: 575.98px) {
	body {
		font-size: 0.8125rem;
	}
	h1, .h1 {
		font-size: 1.75rem;
	}
	h2, .h2 {
		font-size: 1.5rem;
	}
	h3, .h3 {
		font-size: 1.25rem;
	}
	h4, .h4 {
		font-size: 1rem;
	}
	h5, .h5 {
		font-size: 0.875rem;
	}
	h6, .h6 {
		font-size: 0.75rem;
	}
	.card {
		margin-bottom: 0.9375rem;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.header-nav {
		padding-left: 15px;
		padding-right: 15px;
	}
	.header-navbar-rht {
		display: none;
	}
	.main-nav li.login-link {
		display: block;
	}
	.navbar-header {
		width: 100%;
	}
	#mobile_btn {
		left: 0;
		margin-right: 0;
		padding: 0 15px;
		position: absolute;
		z-index: 99;
	}
	.navbar-brand.logo {
		width: 100%;
		text-align: center;
		margin-right: 0;
	}
	.navbar-brand.logo img {
		height: 40px;
	}
	.search-box form {
		display: block;
	}
	.search-box .search-location {
		width: 100%;
		-ms-flex: none;
		flex: none;
	}
	.search-box .search-info {
		width: 100%;
		-ms-flex: none;
		flex: none;
	}
	.banner-wrapper .banner-header h1 {
		font-size: 28px;
	}
	.section-header h2 {
		font-size: 1.5rem;
	}
	.section-header .sub-title {
		font-size: 0.875rem;
	}
	.section-header p {
		font-size: 0.9375rem;
	}
	.footer-title {
		font-size: 1.125rem;
	}
	.booking-schedule.schedule-widget {
		overflow-x: auto;
	}
	.booking-schedule.schedule-widget > div {
		width: 730px;
	}
	.booking-schedule .day-slot ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.booking-schedule .day-slot li {
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		width: 100px;
	}
	.booking-schedule .time-slot ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.booking-schedule .time-slot li {
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		width: 100px;
	}
	.booking-schedule .time-slot li .timing span {
		display: inline-block;
	}
	.booking-schedule .day-slot li.right-arrow {
		right: -20px;
	}
	.booking-user-info .booking-user-img {
		width: 70px;
	}
	.booking-user-info .booking-user-img img {
		height: 70px;
		width: 70px;
	}
	.voice-call-avatar .call-avatar {
		height: 80px;
		width: 80px;
	}
	.call-duration {
		display: block;
		margin-top: 0;
		margin-bottom: 10px;
		position: inherit;
	}
	.end-call {
		margin-top: 10px;
		position: inherit;
	}
	.pagination-lg .page-link {
		font-size: 1rem;
		padding: 0.5rem 0.625rem;
	}
	.row.row-grid > div {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.edit-link {
		font-size: 14px;
		margin-top: 2px;
	}
	.invoice-content {
		padding: 1.25rem;
	}
	.change-avatar .profile-img img {
		height: 80px;
		width: 80px;
	}
	.blog {
		padding: 1.25rem;
	}
	.blog-title {
		font-size: 18px;
	}
	.blog-view .blog-title {
		font-size: 20px;
	}
	.blog-info i {
		font-size: 14px;
	}
	.post-left ul li {
		margin-right: 10px;
	}
	.post-left ul li:last-child {
		margin-right: 10px;
	}
	.about-author-img {
		height: 60px;
		width: 60px;
	}
	.author-details {
		margin-left: 80px;
	}
	.blog-comments .comments-list li img.avatar {
		border-radius: 40px;
		height: 40px;
		width: 40px;
	}
	.blog-comments .comments-list li {
		padding: 10px 0 0 50px;
	}
	.read-more {
		font-size: 14px;
	}
	.section-header span {
		font-size: 16px;
	}
	.btn {
		font-size: 0.875rem;
	}
}

@media only screen and (max-width:479px) {
	.section-search {
		min-height: 410px;
		padding: 30px 15px;
	}
	.mentor-booking a.apt-btn {
		width: 100%;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 160px;
	}
	.login-header h3 a {
		color: #0de0fe;
		float: none;
		font-size: 15px;
		margin-top: 10px;
		text-align: center;
		display: block;
	}
	.appointments .appointment-list {
		text-align: center;
	}
	.appointment-list .profile-info-widget {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.appointment-list .profile-info-widget {
		text-align: center;
	}
	.appointment-list .profile-info-widget .booking-user-img {
		margin: 0 0 15px;
	}
	.appointment-list .profile-info-widget .booking-user-img img {
		border-radius: 50%;
		height: 100px;
		width: 100px;
	}
	.appointment-list .profile-det-info {
		margin-bottom: 15px;
	}
	.appointments .appointment-action {
		margin-top: 0;
	}
	.user-tabs .nav-tabs .nav-item {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
	}
	.review-listing .recommend-btn span {
		display: block;
		margin-bottom: 10px;
	}
	.review-listing > ul li .comments-reply {
		margin-left: 0;
	}
	.schedule-nav .nav-tabs li {
		display: block;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		margin-right: 0;
	}
}


