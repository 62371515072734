
input {
    outline: 0;
    border-width: 0 0 0;
    /* border-color: rgb(104, 104, 104); */
  }
  input:focus {
    border-color: blue;
  }

  input::-webkit-input-placeholder{
      opacity: 100;
  }

textarea {
    outline: 0;
    border-width: 0 0 0;
}

textarea:focus {
    border-color: blue;
  }

.__container {
    background-color: white;
    display: flex;
    margin-top: 20px;
  /* margin-top: 10vh; */
    justify-content: center; 
    align-items: center;

}


.form__container {
    /* width: 600px; */
}

.registration__details__container {
    box-shadow: 0 0 6px rgba(55,67,177,.15);
    max-width: 560px;
    /* min-width: 400px; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    /* border: 1px solid lightgray; */
    padding: 40px;
}

._header {
    display: flex;
    width: 100%;
    flex-direction: column;

}

._header > div > h1 {
    font-weight: 600;
    font-size: 32px;
    padding: 0px 0;
  }

._header > h2 {
    font-size: 30px;
    color: #30b3f0;
}

._header > p {
    font-weight: bolder;
    color: rgb(169, 167, 167);
    padding-top: 9px;
}

.form__group {
padding : 8px 0;
}

.form__group label {
    color: rgb(67, 68, 68);
     font-weight: 500;
    /* font-size: large; */
}

.header__button{
    display: flex;
    flex-direction: column;
    flex: 1;
}


/* .header__button > button {
    margin: 5px 20px;
    width: 320px;
    height: 55px;
    padding: 6px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    color: white;
} */

.header__button > button > span {
    font-weight:400 !important;

    
}

.flex1 {
    flex: 1;
}

.google {
    margin: 5px 10px 5px 0px !important;
    /* width: 230px !important; */
    height: 40px !important;
    padding: 0 !important;
    /* padding: 6px !important; */
    font-size: 14px !important;
    box-shadow: none!important;
    border: 1px !important;
    border-color:rgba(55, 67, 177, 0.36) !important;
    border-style: solid !important;
    border-radius: 5px !important;
    color: #3743B1 !important;
    align-items: center;
    /* background-color:#DB4437 !important; */
    /* padding-left: 100px !important; */
    display: flex !important;
    justify-content: center !important;
    /* border: none !important; */
}

.google:hover {
    box-shadow: 0 0 5px rgba(55, 67, 177, 0.36) !important;
}

.google > div {
    padding: none !important;
    background: none !important;
}

.facebook {
    margin: 5px 0px 5px 10px;
    /* width: 230px; */
    height: 40px;
    padding: 6px;
    font-size: 14px;
    display: flex;
    /* font-weight: bold; */
    color:#3743B1;
    background-color: white;
    box-shadow: none!important;
    border: 1px !important;
    border-color:rgba(55, 67, 177, 0.36) !important;
    border-style: solid !important;
    border-radius: 5px !important;
}

.facebook:hover {
    box-shadow: 0 0 5px rgba(55, 67, 177, 0.36) !important;
}

.facebook > div {
    margin-right: 20px;
    padding-left: 10px;
}
.MuiOutlinedInput-notchedOutline {
    border-color: #3743B1 !important ;
}
.MuiFormLabel-root {
    color: #3743B1 !important;
}
.MuiInputAdornment-root {
    max-height: 3.4em !important;
    padding: 10px !important;
    height: 100% !important;
}
.MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
}
.form__control{
    display: flex !important;
    width: 100%;
    /* height: calc(1.7em + .65rem+1px); */
    /* padding: .375rem .75rem; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 3.9;
    color: #3743B1 !important;
    background-color: #fff;
    /* background-clip: padding-box; */
    /* border-bottom: 1px solid rgb(151, 151, 151); */
    border-radius: .25rem;
    
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
{ @media(max-width: 1300px) {
    .form__control{
        height: auto;
    }
}} */

_::-webkit-full-page-media,.form__control .safari_only{
height: auto;
}

.MuiFormControl-root {
    width: 100% !important;
}

.form-actions {
    margin: 5px 0 0;
    background-color: transparent;
    text-align: center;
    padding: 10px 0 ;
}
.form-actions button {
    width: 130px;
    height: 40px;
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    color: white;
    background-color:#30b3f0;
}

.registration__view__footer {
    padding: 0px;
    color: rgb(67, 68, 68);
    /* font-weight: 500; */
   display: flex;
}

.registration__view__footer > a {
    cursor: pointer;
    color: #3743B1 !important;
    /* font-weight: bolder; */
}

.select__container {
    display: flex;
    flex: 1;
    gap: 20px;
    padding: 10px 0 10px 0;
}


.select__button {
    flex: 1;
    height: 45px;
    font-size: 14px;
    border-radius: 5px;
    /* font-weight: 700; */
    color: #3743B1;
    border:1px solid #3743B1 !important;
    background-color:white;
    outline:none !important;
}

.select__button__active {
    flex: 1;
    height: 45px;
    font-size: 14px;
    border-radius: 5px;
    /* font-weight: 700; */
    color: #fff;
    border:none;
    background-color: #3743B1;
    outline:none !important;
}

@media (max-width: 800px) {
    .__container {
        padding: 10px;
    }
    .form__container {
        width: 320px;
    }
    /* .header__button > button {
        margin: 5px 10px;
        width: 70px;
        height: 35px;
        padding: 4px;
        font-size: 10px;
    } */

    /* .google {
        font-weight: 600;
        font-size: 14px !important;
        background: #3743B1;
        color: whitesmoke;
        border-radius: 5px;
        width: 100%;
        height: 50px;
        margin: 10px 0;
        border: none;
        outline: none;

    } */
    .google > span {
        font-size: 10px !important;
        /* width: 100px !important; */
    }
    .facebook {
        font-size: 10px !important;
        display: flex !important;
        align-items: center !important;
        /* width: 100px !important; */
    }
    /* .facebook {
        margin: 5px 0px 5px 10px;
        width: 230px;
        height: 40px;
        padding: 6px;
        font-size: 14px;
        display: flex;

        color:#3743B1;
        background-color: white;
        box-shadow: none!important;
        border: 1px !important;
        border-color:rgba(55, 67, 177, 0.36) !important;
        border-style: solid !important;
        border-radius: 5px !important;
    } */


 
    .select__container > button {
        width: 75px;
        height: 30px;
        border-radius: 2px;
        font-size: 9px;
        font-weight: 700;
      
    }

    .form-actions button {
        width: 120px;
        height: 35px;

        font-size: 12px;
    }
  
}
.verification__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding:22px 25px;
}

.verification__body > p{
    font-weight: 500;
    font-size: 20px;
    color: grey;
    padding: 10px;
}

.verification__body > h5{
   padding: 40px 0;
    font-size: 16px;
    color: rgb(175, 175, 175);
}

.verification__body > button{
    width: 120px;
    height: 40px;
    color: white;
    background-color: #30b3f0;
    font-weight: 520;
    font-size: 14px;
    outline: none;
    border: none;
    letter-spacing: 0.05em;
 }