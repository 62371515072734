.fa {
    padding-right: 10px;
  }
  
  .cr_icons{
    position: absolute;
    right: 10px;
  }

  .navbar {
    /* background-color: #f2f2f2; */
    /* width: 250px; */
    height: 53px;
    cursor: pointer;
    border: none !important;
  }
  


  .analytics .topButton {
    height: 73px;
    margin-top: -5px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    color: #3743B1;
    float: left;
    border: none;

    /* margin-left: 50px; */
    /* border-bottom: 3px solid transparent; */
  }
  
  /* .analytics:hover .topButton {
    border-bottom: 3px solid #848484;
  } */
  
  .analyticsDropDown {
    color: #3743B1;
    font-weight: normal;
    position: absolute;
    top: 66px;
    left: 20px;
    display: none;
    /* border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2; */
    /* border: 1px solid #dcdacb; */
    /* box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08); */
    background-color: white;
    /* padding : 20px 10px; */

  }
  
  .analyticsDropDown .moduleGroup {
    width: 270px;
    color: #3743B1;
    list-style-type: none;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 23px;
    align-items: center;
    display: flex;
    /* border-bottom: 1px solid #f2f2f2; */
    /* border-top: 0px solid transparent;
    border-left: 5px solid transparent; */
    padding: 8px 15px;
    max-height: 35px;
  }
  
  .modulesSet {
    position: absolute;
    top: -1px;
    left: 271px;
    /* border: 1px solid #f2f2f2; */
    border: 1px solid #dcdacb;
    box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
    display: none;
    background-color: white;
  
  }
  
  .singleModule {
    width: 270px;
    list-style-type: none;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    display: flex;
    /* border-bottom: 1px solid #f2f2f2;
    border-left: 5px solid transparent; */
    padding: 8px 15px;
    max-height: 35px;
  }
  
  .visible {
    display: block;
  }