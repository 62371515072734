.registration__page__two {
   display: grid;
   place-items: center;
    width: 100%;
    /* height: 100vh; */
    /* background-color: whitesmoke; */
    overflow: scroll;
    padding: 12px 0px;
}

.registration__details__container {
    width: 100%;
    max-width: 540px;
    /* margin: 30px 0; */
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 10px;
    box-shadow: 2px 0 8px rgba(0,0,0,.15) ;
}

.registration__details__img {
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
}

.ant-upload-picture-card-wrapper {
    display: flex !important;
    justify-content: center !important;
}

.registration__details__img > img {
width: 70px;

}

.registration__details__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.registration__details__footer > button {
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    border:none;
    background-color: #3743B1;
    height: 36px;
    outline: none;
    margin-bottom: 10px;
}

.registration__details__footer > button:hover {
    color: #3743B1;
    border: #3743B1 1px solid;
    background-color: white;

}


.registration__details__footer > div {
display: flex;
flex-direction: column;
padding: 5px 0;
align-items: center;
font-size: 14px;
font-weight: 500;
color: rgb(175, 175, 175);
}


@media (max-width: 600px) {
.registration__details__container {
    padding: 20px 0px;
    /* width: 380px; */
}

.registration__details__footer > div {
    font-size: 12px;
    }
}

.name-select__multi-value__remove {
    padding-right: 8px !important;
    
  }
  
.name-select__multi-value__remove >svg {
    background-color: #3743B1 !important;
    border-radius: 15px !important;
    color: #fff !important;
    }

.name-select__multi-value__remove >svg:hover {
    background-color: white !important;
    border-radius: 15px !important;
    color: #3743B1 !important;
    border: 1px;
    border-style: solid;
    border-color: #3743B1;
    }

    .name-select__menu {
        z-index: 500 !important;
      }

