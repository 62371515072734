.timeWrapper{
    width: 30%;
    background: #3743B1;
    text-decoration-color: ivory;
    padding: 3rem 0;
}

.timeWrapper h1{
    text-align: center;
    color: ivory
}

.time{
    display: flex;
    flex-direction: column;
    justify-items: center;
}

.time-content {
    margin: 0 auto;
    width: 260px;
    height: 260px;
    /* margin-top: 25px; */
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    border: 5px solid white;
    border-radius: 50%;
    padding: 5rem;
}

.time-content span {
    font-size: 4rem;
    color: ivory;
}

.controls{
    margin-top: 3rem;
    display: flex;
    justify-content: space-around;
}

.controls button {
    width: 100px;
    font-size: 1.1rem;
    font-weight: 700;
    background-color: white;
    color: #3743B1;
    border-radius: 15px;
    outline: none;
    border: none;
    justify-content: center;
    padding: 1.3rem;
    height: 35px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
    margin: 15px;
    text-transform: uppercase;}

    .controls button:hover{
        cursor: pointer;
        background-color: #3743B1;
        border: 2px solid white;
        color: white;
    }




