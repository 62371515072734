.navbar__ctr {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px 20px;
}

.user_name {
    position: relative;
    top: 3px;
    padding: 0 20px;
    font-size: 24px;
    font-weight: 500;
    color: rgb(121, 139, 179);
}

._nav_link {
    color: #26292C !important;
}

._nav_link:hover {
    text-decoration: #3743B1 underline;
}

.sidebar__btn{
    position: fixed;
    top:15px;
    right:0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 2px 0 8px rgba(0,0,0,.15);
    border-radius: 10px 0 0 10px;
}

.sidebar_icon {
    color: rgb(79, 79, 252);
}


 
.ant-drawer-body > a > p > h5 {
    color: rgb(76, 76, 241);
    padding: 2px 10px;
}

.ant-drawer-body > a > p :hover {
    background-color: rgb(235, 235, 250);
    border-right: 3px solid  rgb(55, 55, 241);
}
.main-logo img{
    width: 30%;
}