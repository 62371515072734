.notifications {
  /* background-color: rgb(223, 242, 255); */
  /* height: 88vh; */
  /* overflow: scroll; */
}

.notifications__header {
  background-color: #fff;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  height:4.4rem;
}

.notifications__header > span {
  padding: 23px 35px;
  cursor: pointer;
}

.span__active {
  border-bottom: 3px solid blue;
}

.icon__active {
  color: blue !important;
}

.Notifications__header__icon {
  color: grey;
}

.notifications__body {
  /* margin: 90px auto 20px; */
  background-color: #FAFAFA;
  padding: 20px;
  max-width: 282px;
  display: flex;
  flex-direction: column;
  height: max-content;
  /* border-radius: 10px; */
  box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.15);

  overflow-y: scroll;
}

/* .notifications__body__chat {} */

.notifications__body__chat > h2 {
  text-align: center;
}
.chat__card {
  display: flex;
  padding: 15px 10px;
  align-items: center;
  /* justify-content: space-between; */
  border-bottom: 1px solid #ccc;
}

.chat__card__left {
  display: flex;
  /* align-items: center; */
}

.chat__card__details {
  padding: 0 15px;
  color: grey;
  font-size: 15px;
  font-weight: 500;
}

.chat__card__time {
  color: grey;
}

.friend__cards {
}

.friend__card__button {
  display: flex;
}

.friend__card__button > button {
  width: 75px;
  height: 33px;
  border-radius: 12px;
  color: white;
  font-weight: 500;
  margin: 0 5px;
}

.troubleshoot__details {
  padding: 10px 15px;
  font-size: 15px;
  color: grey;
}


.request_accept {
  background-color: #3743B1 !important;
  color: white !important;
}

.request_accept:hover {
  background-color: white !important;
  /* border-color: #3743B1 !important; */
  color: #3743B1 !important;
  border: 1px solid #3743B1 !important
}

.request_accept:hover > span >span {
  color: #1BA20C !important;
}
.request_decline {
  background-color: #3743B1 !important;
  color: white !important;
}


.request_decline:hover {
  background-color: white !important;
  /* border-color: #3743B1 !important; */
  color: #3743B1 !important;
  border: 1px solid #3743B1 !important
}

.request_decline:hover >span >span {
  color: #BB0000 !important;
}

@media (max-width: 500px) {
  
  .friend__card__button {
    padding-top: 2rem;
  }
}

@media (max-width: 480px) {
  .request_mobile_card {
    flex-direction: column !important;
    margin-top: 70px;
  }
  .request_mobile_tech_fix {
    flex-direction: column !important;
  }
  .request_mobile_budget_fix {
    justify-content: unset !important;
  }
  .request_mobile_button_fix {
    flex-direction: unset !important;
  }
}